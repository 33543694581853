import style from "./TxPendingView.module.scss";

export default function TxPendingView(props: {}) {
    return (
        <div className={style.pendingbox}>
            <div className={style.icon} />
            <div className={style.text}>{"Waiting for receipt..."}</div>
        </div>
    );
}
