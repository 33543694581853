import style from "./StakeLpUnstake.module.scss";
import StakeLpUnstakeAuto from "./StakeLpUnstakeAuto";
import StakeLpUnstakeManual from "./StakeLpUnstakeManual";
import { StakeLpStakeManualMode, StakeLpStakeMode } from "../StakeLp";

export default function StakeLpUnstake(props: {
    mode1: StakeLpStakeMode;
    mode2: StakeLpStakeManualMode;
    estimateParams?: ResponseLpEstimate;
    unstakeLpAutoPressed: (amount: string) => void;
    divideLpPressed: (amount: string) => void;
    unstakeLpPressed: (amount: string) => void;
}) {
    return (
        <div className={style.container}>
            {props.mode1 === StakeLpStakeMode.auto ? (
                <StakeLpUnstakeAuto
                    estimateParams={props.estimateParams}
                    unstakeLpAutoPressed={props.unstakeLpAutoPressed}
                />
            ) : (
                <StakeLpUnstakeManual
                    mode={props.mode2}
                    estimateParams={props.estimateParams}
                    divideLpPressed={props.divideLpPressed}
                    unstakeLpPressed={props.unstakeLpPressed}
                />
            )}
        </div>
    );
}
