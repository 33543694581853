import style from "./MyRewards.module.scss";

import { NormalButton2 } from "../../../common/NormalButton/NormalButton";

import { useEffect, useState } from "react";
import * as Utils from "../../../../libs/utils";

import TitleLabel from "../../../common/Labels/TitleLabel";
import { leftGreaterThanRight, minus } from "../../../../libs/Math";
import { useRecoilValue } from "recoil";
import { walletState } from "../../../../libs/contexts";
import classNames from "classnames/bind";
import LockedDepositListPopup from "../Common/LockedDepositListPopup";
const cx = classNames.bind(style);

export default function MyRewards(props: {
    summary: CampaignSummary;
    campaignAddress: string;
    claimPressed: () => void;
}) {
    const wallet = useRecoilValue(walletState);
    const [showLockedDepositPopup, setShowLockedDepositPopup] = useState(-1);

    const [claimable, setClaimable] = useState(false);
    const p = props.summary.participation!;

    const claimable_deposit = props.summary.deposit
        ? props.summary.deposit.claimable
        : "0";
    const total = props.summary.deposit
        ? props.summary.deposit.total_deposited
        : "0";

    useEffect(() => {
        const body: any = document.querySelector("body");
        body.style.overflow = showLockedDepositPopup !== -1 ? "hidden" : "auto";
    }, [showLockedDepositPopup]);

    useEffect(() => {
        if (props.summary.participation) {
            const deposit = props.summary.deposit
                ? props.summary.deposit.claimable
                : "0";

            const p = props.summary.participation;
            setClaimable(
                leftGreaterThanRight(p.participation_reward.unlocked, 0) ||
                    leftGreaterThanRight(p.referral_reward.unlocked, 0) ||
                    leftGreaterThanRight(deposit, 0)
            );
        } else {
            setClaimable(false);
        }
    }, [props.summary]);

    if (
        !props.summary.participation ||
        wallet === undefined ||
        props.summary.participation.last_participated_at <= 0
    ) {
        return <div />;
    }

    return (
        <div className={style.container}>
            <div className={style.header_myrewards_title}>
                <TitleLabel
                    fontWeight={"light"}
                    text={"My Rewards"}
                    size={20}
                    color={"#fff"}
                />
            </div>
            <div className={style.header_myrewards_wrap}>
                <div
                    className={cx(style.header_myrewards_bg_footer, {
                        isIpc: true,
                    })}
                />
                {/* pc version */}
                <div
                    className={cx(style.header_myrewards_contents, {
                        isIpc: true,
                    })}
                >
                    <div className={style.myrewards_contents_titles}>
                        <ParticipationTitle />
                    </div>
                    <div className={style.myrewards_contents_dummy} />

                    <div className={style.myrewards_contents_titles}>
                        <ParticipationDetail
                            title={"Sharing"}
                            claimable={p.referral_reward.unlocked}
                            locked={p.referral_reward.locked}
                            decimals={6}
                            symbol={"VKR"}
                            pressed={() => {
                                setShowLockedDepositPopup(2);
                            }}
                        />
                    </div>
                    <div className={style.myrewards_contents_dummy} />
                    <div className={style.myrewards_contents_titles}>
                        <ParticipationDetail
                            title={"Campaign Deposit"}
                            tooltip={
                                "Current Claimable deposit collateral/Currently locked deposit collateral"
                            }
                            claimable={claimable_deposit}
                            locked={minus(total, claimable_deposit)}
                            decimals={6}
                            symbol={"VKR"}
                            pressed={() => {
                                setShowLockedDepositPopup(1);
                            }}
                        />
                    </div>
                    <div className={style.myrewards_contents_dummy} />
                    <div className={style.myrewards_claim}>
                        <div className={style.myrewards_claimbutton}>
                            <NormalButton2
                                title={"Claim All Rewards"}
                                onClick={() => {
                                    if (wallet) {
                                        props.claimPressed();
                                    }
                                }}
                                disabled={!claimable}
                            />
                        </div>
                    </div>
                </div>

                {/* mobile version */}
                <div
                    className={cx(style.mobile_header_myrewards_contents, {
                        isIpc: true,
                    })}
                >
                    <div className={style.mobile_myrewards_contents_titles}>
                        <MobileParticipationDetail
                            title={"Sharing"}
                            claimable={p.referral_reward.unlocked}
                            locked={p.referral_reward.locked}
                            decimals={6}
                            symbol={"VKR"}
                            pressed={() => {
                                setShowLockedDepositPopup(2);
                            }}
                        />
                    </div>
                    <div className={style.mobile_myrewards_contents_dummy} />
                    <div className={style.mobile_myrewards_contents_titles}>
                        <MobileParticipationDetail
                            title={"Campaign Deposit"}
                            claimable={claimable_deposit}
                            locked={minus(total, claimable_deposit)}
                            decimals={6}
                            symbol={"VKR"}
                            pressed={() => {
                                setShowLockedDepositPopup(1);
                            }}
                        />
                    </div>
                    <div className={style.mobile_myrewards_contents_dummy} />
                    <div className={style.mobile_myrewards_claim}>
                        <div className={style.mobile_myrewards_claimbutton}>
                            <NormalButton2
                                title={"Claim All Rewards"}
                                onClick={() => {
                                    if (wallet) {
                                        props.claimPressed();
                                    }
                                }}
                                disabled={!claimable}
                            />
                        </div>
                    </div>
                </div>
            </div>

            {showLockedDepositPopup !== -1 ? (
                <LockedDepositListPopup
                    show={showLockedDepositPopup}
                    setShow={setShowLockedDepositPopup}
                    summary={props.summary}
                />
            ) : (
                <div />
            )}
        </div>
    );
}

export function ParticipationTitle() {
    return (
        <div className={style.ptitle_container}>
            <div className={style.ptitle_info}>
                <TitleLabel
                    fontWeight={"light"}
                    text={"Participation Info"}
                    size={12}
                    color={"rgba(255,255,255,0.7)"}
                />
            </div>
            <div className={style.ptitle_locked}>
                <div className={style.ptitle_locked_title}>
                    <TitleLabel
                        fontWeight={"light"}
                        text={"Locked"}
                        size={12}
                        color={"rgba(255,255,255,0.7)"}
                    />
                </div>
                <div className={style.ptitle_locked_ico} />
            </div>
            <div className={style.ptitle_claimable}>
                <TitleLabel
                    fontWeight={"light"}
                    text={"Claimable"}
                    size={12}
                    color={"rgba(255,255,255,0.7)"}
                />
            </div>
        </div>
    );
}

export function ParticipationDetail(props: {
    title: string;
    tooltip?: string;
    claimable: string;
    locked: string;
    decimals: number;
    symbol: string;
    pressed?: () => void;
}) {
    return (
        <div className={style.pdetail_container}>
            <div className={style.pdetail_info}>
                <div
                    className={cx(style.pdetail_info_ico, {
                        sharing: props.title === "Sharing",
                        deposit: props.title === "Campaign Deposit",
                    })}
                />
                <div className={style.pdetail_info_title}>
                    <TitleLabel
                        fontWeight={"light"}
                        text={props.title}
                        size={16}
                        color={"#fff"}
                        tooltip={props.tooltip}
                    />
                </div>
            </div>
            <div className={style.pdetail_locked}>
                <div className={style.pdetail_locked_wrap}>
                    <div className={style.pdetail_locked_amount}>
                        <div
                            style={{
                                cursor: props.pressed ? "pointer" : "auto",
                            }}
                            onClick={() => {
                                if (props.pressed) {
                                    props.pressed();
                                }
                            }}
                        >
                            <TitleLabel
                                size={16}
                                text={Utils.getDecimal(
                                    props.locked,
                                    true,
                                    props.decimals
                                )}
                                color={"#f93660"}
                                underline={props.pressed ? true : false}
                                symbol={props.symbol}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className={style.pdetail_claimable}>
                <div className={style.pdetail_claimable_wrap}>
                    <div className={style.pdetail_claimable_amount}>
                        <TitleLabel
                            fontWeight={"light"}
                            text={Utils.getDecimal(
                                props.claimable,
                                true,
                                props.decimals
                            )}
                            size={16}
                            color={"#ffe646"}
                        />
                    </div>
                    <div className={style.pdetail_claimable_symbol}>
                        <TitleLabel
                            fontWeight={"medium"}
                            text={props.symbol}
                            size={12}
                            color={"#ffe646"}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

function MobileParticipationDetail(props: {
    title: string;
    claimable: string;
    decimals: number;
    locked: string;
    symbol: string;
    pressed?: () => void;
}) {
    return (
        <div className={style.mobile_pdetail_container}>
            <div className={style.mobile_title}>
                <div
                    className={cx(style.mobile_title_ico, {
                        sharing: props.title === "Sharing",
                        deposit: props.title === "Campaign Deposit",
                    })}
                />
                <div className={style.mobile_title_text}>
                    <TitleLabel
                        fontWeight={"light"}
                        text={props.title}
                        size={16}
                        color={"#fff"}
                    />
                </div>
            </div>
            <div className={style.mobile_contents}>
                <div className={style.mobile_contents_locked}>
                    <div className={style.mobile_contents_locked_title}>
                        <div className={style.locked_title_text}>
                            <TitleLabel
                                fontWeight={"light"}
                                text={"Locked"}
                                size={12}
                                color={"rgba(255,255,255,0.5)"}
                            />
                        </div>
                        <div className={style.locked_title_ico} />
                    </div>
                    <div className={style.mobile_contents_locked_amount}>
                        <div className={style.pdetail_locked_wrap}>
                            <div className={style.pdetail_locked_amount}>
                                <div
                                    style={{
                                        cursor: props.pressed
                                            ? "pointer"
                                            : "auto",
                                    }}
                                    onClick={() => {
                                        if (props.pressed) {
                                            props.pressed();
                                        }
                                    }}
                                >
                                    <TitleLabel
                                        size={16}
                                        text={Utils.getDecimal(
                                            props.locked,
                                            true,
                                            props.decimals
                                        )}
                                        color={"#f93660"}
                                        underline={props.pressed ? true : false}
                                        symbol={props.symbol}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={style.mobile_contents_dummy} />
                <div className={style.mobile_contents_claimable}>
                    <div className={style.mobile_contents_claimable_title}>
                        <div className={style.claimable_title_text}>
                            <TitleLabel
                                fontWeight={"light"}
                                text={"Claimable"}
                                size={12}
                                color={"rgba(255,255,255,0.5)"}
                            />
                        </div>
                    </div>
                    <div className={style.mobile_contents_claimable_amount}>
                        <div className={style.pdetail_claimable_wrap}>
                            <div className={style.pdetail_claimable_amount}>
                                <TitleLabel
                                    fontWeight={"light"}
                                    text={Utils.getDecimal(
                                        props.claimable,
                                        true,
                                        props.decimals
                                    )}
                                    size={16}
                                    color={"#ffe646"}
                                />
                            </div>
                            <div className={style.pdetail_claimable_symbol}>
                                <TitleLabel
                                    fontWeight={"medium"}
                                    text={props.symbol}
                                    size={12}
                                    color={"#ffe646"}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
