import style from "./DetailButton.module.scss";
import classNames from "classnames/bind";

const cx = classNames.bind(style);

export default function DetailButton(props: { hovercheck: boolean }) {
    return (
        <div className={cx(style.container, { hovercheck: props.hovercheck })}>
            <div className={style.detail}>{"Details"}</div>
            <div className={style.arrow} />
        </div>
    );
}
