import BigNumber from "bignumber.js";

export function plus(a: BigNumber.Value, b: BigNumber.Value): string {
    return new BigNumber(a).plus(b).toString();
}
export function minus(a: BigNumber.Value, b: BigNumber.Value): string {
    return new BigNumber(a).minus(b).toString();
}

export function multiply(a: BigNumber.Value, b: BigNumber.Value): string {
    return new BigNumber(a).multipliedBy(b).toString();
}

export function divide(a: BigNumber.Value, b: BigNumber.Value): string {
    return new BigNumber(a).dividedBy(b).toString();
}

export function min(a: BigNumber.Value, b: BigNumber.Value): string {
    return BigNumber.min(a, b).toString();
}

export function max(a: BigNumber.Value, b: BigNumber.Value): string {
    return BigNumber.max(a, b).toString();
}

export function isZero(a: BigNumber.Value): boolean {
    return new BigNumber(a).isEqualTo(0);
}

export function equalTo(a: BigNumber.Value, b: BigNumber.Value): boolean {
    return new BigNumber(a).isEqualTo(b);
}

export function leftGreaterThanRight(
    a: BigNumber.Value,
    b: BigNumber.Value
): boolean {
    return new BigNumber(a).isGreaterThan(b);
}
export function leftLessThanRight(
    a: BigNumber.Value,
    b: BigNumber.Value
): boolean {
    return new BigNumber(a).isLessThan(b);
}

export function isNumber(a: BigNumber.Value): boolean {
    const n = new BigNumber(a);
    return !n.isNaN() && n.isFinite();
}

export function sqrt(a: BigNumber.Value): string {
    return new BigNumber(a).sqrt().toString();
}

export function fixed(a: BigNumber.Value, b: number): string {
    return new BigNumber(a).toFixed(b, 1);
}
