import style from "./TitleLabel.module.scss";
import TooltipIcon from "../TooltipIcon/TooltipIcon";
import Skeleton from "react-loading-skeleton";
import classNames from "classnames/bind";
import { useEffect, useState } from "react";

const cx = classNames.bind(style);

export default function TitleLabel(props: {
    size?: number;
    text?: string;
    symbol?: string;
    tooltip?: string;
    color?: any;
    underline?: boolean;
    opacity?: number;
    fontWeight?: "light" | "medium" | "bold";
    skeleton?: {
        width: number;
    };
    symbolFontWeight?: "light" | "medium" | "bold";
    isCampaignfalse?: boolean;
    hovercheck?: boolean;
}) {
    const [fontSize, setFontSize] = useState(props.size ?? 14);

    function onResize() {
        const size = props.size ? props.size : 14;

        if (window.innerWidth <= 320) {
            setFontSize(size * 0.8);
        } else if (window.innerWidth > 320) {
            setFontSize(size);
        }
    }

    useEffect(() => {
        window.addEventListener("resize", onResize);
        return () => {
            window.removeEventListener("resize", onResize);
        };
    }, []);

    let fontFamily = "medium";
    if (props.fontWeight === "light") {
        fontFamily = "book";
    } else if (props.fontWeight === "bold") {
        fontFamily = "bold";
    }

    let symbolFontFamily = "medium";
    if (props.symbolFontWeight === "light") {
        symbolFontFamily = "book";
    } else if (props.symbolFontWeight === "bold") {
        symbolFontFamily = "bold";
    }

    return (
        <div className={style.container}>
            {!props.text && props.skeleton ? (
                <Skeleton
                    width={props.skeleton.width}
                    height={props.size ?? 14}
                />
            ) : (
                <div
                    className={cx(style.label, {
                        campaignfalse: props.isCampaignfalse,
                        hovercheck: props.hovercheck,
                    })}
                    style={{
                        fontFamily: fontFamily,
                        fontSize: fontSize + "px",
                        // fontSize: props.size + "px",
                        color: props.color ? props.color : "white",
                        textDecoration: props.underline ? "underline" : "none",
                        opacity: props.opacity ? props.opacity : 1,
                    }}
                >
                    {props.text ?? ""}
                    <small
                        style={{
                            fontFamily: props.symbolFontWeight
                                ? symbolFontFamily
                                : fontFamily,
                        }}
                    >
                        {props.symbol ? " " + props.symbol : ""}
                    </small>
                </div>
            )}

            {props.tooltip ? <div style={{ width: "4px" }} /> : <div />}

            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    marginTop: "-1px",
                }}
            >
                {props.tooltip ? (
                    <TooltipIcon
                        tooltip={props.tooltip}
                        isCampaignfalse={props.isCampaignfalse}
                        hovercheck={props.hovercheck}
                    />
                ) : (
                    <div />
                )}
            </div>
        </div>
    );
}
