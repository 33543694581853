import { environment, wasmQuery } from "../api";

import { GovCreateType } from "../../../views/Governance/CreateForm/GovernanceCreateForm";
import { queryTokenBalance } from "../trade/query";
import { govApr } from "../rest";
import {
    divide,
    fixed,
    isNumber,
    isZero,
    leftGreaterThanRight,
    leftLessThanRight,
    minus,
    multiply,
} from "../../Math";

import * as Utils from "../../utils";

export async function govTicketStakerState(
    address: string
): Promise<ResponseGovTicketStakerState> {
    return wasmQuery(environment().contracts.governance, {
        ticket_staker_state: {
            address: address,
        },
    })
        .then((r: ResponseGovTicketStakerState) => {
            return {
                pending_reward: r.pending_reward,
            };
        })
        .catch((e) => {
            return {
                pending_reward: "0",
            };
        });
}

export async function govTicketConfig(): Promise<ResponseGovTicketConfig> {
    return await wasmQuery(environment().contracts.governance, {
        ticket_config: {},
    });
}

export async function govTicketTokenAddress(): Promise<string> {
    return wasmQuery(environment().contracts.governance, {
        ticket_config: {},
    })
        .then((r: any) => {
            return r.ticket_token;
        })
        .catch((e) => {
            return "";
        });
}

export async function govStakerState(
    address: string
): Promise<ResponseGovStakerInfo> {
    return wasmQuery(environment().contracts.governance, {
        staker_state: {
            address: address,
        },
    })
        .then((r) => {
            const votes = (r.votes as any[]).map((item) => {
                if (item.length === 2) {
                    const info: {
                        amount: string;
                        voter: string;
                        option: string;
                    } = item[1];

                    if (info.voter === address) {
                        return info;
                    }
                }

                return {
                    amount: "0",
                    option: "unknown",
                    voter: address,
                };
            });

            let locked = "0";
            votes.forEach((item) => {
                if (leftGreaterThanRight(item.amount, locked)) {
                    locked = item.amount;
                }
            });

            return {
                balance: r.balance,
                share: r.share,
                vote_locked: locked,
                votes: r.votes,
            };
        })
        .catch((e) => {
            return {
                balance: "0",
                share: "0",
                vote_locked: "0",
                votes: [],
            };
        });
}

export async function govState(
    address: string | undefined
): Promise<StakeGovHeaderInfo> {
    const apr = await govApr();

    const tokenBalance = address
        ? (await queryTokenBalance(undefined, address)).amount
        : "0";

    const state: ResponseGovStakeStateInfo = await wasmQuery(
        environment().contracts.governance,
        {
            staking_state: {},
        }
    );

    let balance = "0";
    let vote_locked = "0";
    let ticket = "0";
    if (address) {
        const info = await govStakerState(address);
        balance = info.balance;
        vote_locked = info.vote_locked;

        const t = await govTicketStakerState(address);
        ticket = t.pending_reward;
    }

    return {
        apr: apr,
        totalStaked: state.total_balance ?? "0",
        stakable: tokenBalance,
        staked: balance,
        vote_locked: vote_locked,
        unstakable: minus(balance, vote_locked),
        pending: ticket,
    };
}

export async function pollConfig(): Promise<ResponsePollConfig> {
    const config: ResponsePollConfig = await wasmQuery(
        environment().contracts.governance,
        {
            poll_config: {},
        }
    );

    return config;
}

export function calcPollVotes(
    info: ResponsePollDetail,
    totalStaked: string
): PollVotesInfo {
    const yesVote = info.yes_votes;
    const noVote = info.no_votes;
    const abstainVote = info.abstain_votes;
    let total = totalStaked;

    if (info.staked_amount) {
        total = info.staked_amount;
    }

    if (info.total_balance_at_end_poll) {
        total = info.total_balance_at_end_poll;
    }

    const yesRate = isZero(total)
        ? 0
        : parseFloat(fixed(multiply(divide(yesVote, total), 100), 6));
    const noRate = isZero(total)
        ? 0
        : parseFloat(fixed(multiply(divide(noVote, total), 100), 6));
    const abstainRate = isZero(total)
        ? 0
        : parseFloat(fixed(multiply(divide(abstainVote, total), 100), 6));

    const formattedYes = leftLessThanRight(yesRate, "0.01")
        ? "< 0.01%"
        : Utils.getPercentFormat(divide(yesRate, 100)) + "%";
    const formattedNo = leftLessThanRight(noRate, "0.01")
        ? "< 0.01%"
        : Utils.getPercentFormat(divide(noRate, 100)) + "%";
    const formattedAbstain = leftLessThanRight(abstainRate, "0.01")
        ? "< 0.01%"
        : Utils.getPercentFormat(divide(abstainRate, 100)) + "%";

    return {
        value: {
            yes: yesVote,
            no: noVote,
            abstain: abstainVote,
            total: total,
        },
        rate: {
            yes: yesRate,
            no: noRate,
            abstain: abstainRate,
        },
        display: {
            yes: formattedYes,
            no: formattedNo,
            abstain: formattedAbstain,
        },
    };
}

export async function pollInfo(
    id: string,
    address: string | undefined
): Promise<PollInfo> {
    if (!isNumber(id)) {
        throw Error("id nan");
    }

    const config: ResponsePollConfig = await pollConfig();

    const info: ResponsePollDetail = await wasmQuery(
        environment().contracts.governance,
        {
            poll: {
                poll_id: parseInt(id),
            },
        }
    );

    const state = await govState(address);
    const votes = calcPollVotes(info, state.totalStaked);

    return {
        config: config,
        info: info,
        votes: votes,
    };
}

export async function pollVoters(
    id: number,
    limit: number,
    startAfter?: string
): Promise<ResponsePollVoter[]> {
    const order = "desc";

    return (
        await wasmQuery(environment().contracts.governance, {
            voters: {
                poll_id: id,
                start_after: startAfter,
                limit: limit,
                order_by: order,
            },
        })
    ).voters;
}

export async function governancePollList(
    limit: number,
    start_after?: number,
    filter?: string
): Promise<ResponsePollDetail[]> {
    const list: ResponsePollDetail[] = (
        await wasmQuery(environment().contracts.governance, {
            polls: {
                filter: filter,
                start_after: start_after,
                limit: limit,
                order_by: "desc",
            },
        })
    ).polls;
    return list;
}

export function getGovernancePollType(info: ResponsePollDetail): GovCreateType {
    if (info.executions) {
        if (info.executions.length > 0) {
            const msg = JSON.parse(atob(info.executions[0].msg));

            if (msg["transfer"]) {
                return GovCreateType.CommunityGrants;
            } else if (msg["update_poll_config"]) {
                return GovCreateType.ModifyGovParameter;
            } else if (msg["update_config"]) {
                return GovCreateType.ModifyCampaignParameter;
            }
        }
    }

    return GovCreateType.Text;
}

export function getGovernancePollTypeName(type: GovCreateType): string {
    if (type === GovCreateType.ModifyCampaignParameter) {
        return "Modify Campaign Parameters";
    } else if (type === GovCreateType.ModifyGovParameter) {
        return "Modify Governance Parameters";
    } else if (type === GovCreateType.CommunityGrants) {
        return "Community Grants";
    } else if (type === GovCreateType.Text) {
        return "Text Proposal";
    }

    return "";
}
