import style from "./ComboView.module.scss";
import { useState } from "react";
import ComboBox from "../ComboBox/ComboBox";

export default function ComboView(props: {
    list: { value: string; display: string }[];
    selected: number;
    setSelected: (index: number) => void;
}) {
    const [showCombo, setShowCombo] = useState(false);

    return (
        <div
            className={style.container}
            onClick={() => {
                setShowCombo(true);
            }}
        >
            <div className={style.text}>
                {props.list[props.selected].display}
            </div>
            <div className={style.icon} />

            <ComboBox
                showCombo={showCombo}
                setShowCombo={setShowCombo}
                selected={props.selected}
                setSelected={(index) => {
                    props.setSelected(index);
                }}
                list={props.list}
            />
        </div>
    );
}
