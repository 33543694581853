import style from "./GovernanceCreate.module.scss";

import PageHeader from "../common/PageHeader/PageHeader";
import { PATHS } from "../../App";
import { GovCreateType } from "./CreateForm/GovernanceCreateForm";
import { getGovernancePollTypeName } from "../../libs/api/governance/execution";

export default function GovernanceCreate(props: any) {
    return (
        <div className={style.container}>
            <PageHeader title={"Choose a poll"} docs="" />

            <div className={style.card_container}>
                <Card
                    title={getGovernancePollTypeName(
                        GovCreateType.ModifyCampaignParameter
                    )}
                    subtitle="Propose for parameter changes in the campaign "
                    pressed={() => {
                        props.history.push(PATHS.governanceCreateForm, {
                            type: GovCreateType.ModifyCampaignParameter,
                        });
                    }}
                />

                <Card
                    title={getGovernancePollTypeName(
                        GovCreateType.ModifyGovParameter
                    )}
                    subtitle="Propose for parameter changes in VKR Governance"
                    pressed={() => {
                        props.history.push(PATHS.governanceCreateForm, {
                            type: GovCreateType.ModifyGovParameter,
                        });
                    }}
                />

                <Card
                    title={getGovernancePollTypeName(
                        GovCreateType.CommunityGrants
                    )}
                    subtitle="Propose for VKR community funded development work"
                    pressed={() => {
                        props.history.push(PATHS.governanceCreateForm, {
                            type: GovCreateType.CommunityGrants,
                        });
                    }}
                />
                <Card
                    title={getGovernancePollTypeName(GovCreateType.Text)}
                    subtitle="Submit a text proposal"
                    pressed={() => {
                        props.history.push(PATHS.governanceCreateForm, {
                            type: GovCreateType.Text,
                        });
                    }}
                />
            </div>
        </div>
    );
}

function Card(props: { title: string; subtitle: string; pressed: () => void }) {
    return (
        <div className={style.card} onClick={props.pressed}>
            <div className={style.col1}>
                <div className={style.text1}>{props.title}</div>
                <div className={style.text2}>{props.subtitle}</div>
            </div>
            <div className={style.col2}>
                <div className={style.icon} />
            </div>
        </div>
    );
}
