import style from "./CopyButton.module.scss";
import classNames from "classnames/bind";
import * as Utils from "../../../libs/utils";
import { useState } from "react";
const cx = classNames.bind(style);

export default function CopyButton(props: {
    copyString: string;
    setRadius?: boolean;
}) {
    const [copyString, setCopyString] = useState("Copy");

    return (
        <div
            className={cx(style.container, {
                radius: props.setRadius,
            })}
            onClick={() => {
                Utils.copyString(props.copyString);
                setCopyString("Copied");
                setTimeout(() => {
                    setCopyString("Copy");
                }, 1000);
            }}
        >
            <div className={style.title}>{copyString}</div>
        </div>
    );
}
