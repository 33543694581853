import style from "./MainSectionBurned.module.scss";
import * as Utils from "../../libs/utils";
import TitleLabel from "../common/Labels/TitleLabel";
import { useEffect, useRef } from "react";
import { useState } from "react";
import { refreshState } from "../../libs/contexts";
import { useRecoilValue } from "recoil";
import { environment, tokenInfo } from "../../libs/api/api";
import { minus } from "../../libs/Math";

const TOTAL_SUPPLY = 1_000_000_000_000000;

export default function MainSectionBurned() {
    const refresh = useRecoilValue(refreshState);
    const [burned, setBurned] = useState("0");
    const isLoading = useRef(false);

    const [burnNum, setBurnNum] = useState<(string | undefined)[]>([
        undefined,
        undefined,
    ]);

    useEffect(() => {
        if (burned) {
            const total = Utils.getDecimal(burned, true).split(".");
            let burn_start = total[0];
            let burn_end = total.length > 1 ? total[1] : undefined;

            setBurnNum([burn_start, burn_end]);
        }
    }, [burned]);

    useEffect(() => {
        if (isLoading.current) {
            return;
        }
        isLoading.current = true;

        tokenInfo(environment().contracts.token)
            .then((r) => {
                setBurned(minus(TOTAL_SUPPLY, r.total_supply));
                isLoading.current = false;
            })
            .catch((e) => {
                setBurned("0");
                isLoading.current = false;
            });
    }, [refresh]);

    return (
        <div className={style.container}>
            <div className={style.icon_container}>
                <div className={style.icon} />
            </div>

            <div className={style.inner_container}>
                <TitleLabel
                    tooltip={"Total Burned VKR"}
                    text={"Total Burned VKR"}
                    color={"#fff"}
                    fontWeight={"light"}
                />
                <div className={style.value}>
                    <div className={style.burn_wrap}>
                        <div className={style.burn_num}>
                            <TitleLabel
                                size={30}
                                fontWeight={"medium"}
                                text={burnNum[0]}
                                color={"#fd5214"}
                                symbol={
                                    burnNum[1]
                                        ? "." + burnNum[1] + " VKR"
                                        : "VKR"
                                }
                                skeleton={{ width: 100 }}
                            />
                        </div>
                        <div className={style.burn_num_mobile}>
                            <TitleLabel
                                size={16}
                                fontWeight={"medium"}
                                text={burnNum[0]}
                                color={"#fd5214"}
                                symbol={"VKR"}
                                skeleton={{ width: 100 }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
