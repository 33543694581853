import style from "./VPAlert.module.scss";
import { Boxcheck } from "./VPMain";
import classNames from "classnames/bind";

const cx = classNames.bind(style);

export default function VPAlert(props: {
    boxchecked: Boxcheck;
}) {
    return (
        <div className={style.container}>
            <div className={style.row}>
                <div
                    className={cx(style.icon, {
                        checked: props.boxchecked === Boxcheck.checked,
                    })}
                />
                <div
                    className={cx(style.content, {
                        checked: props.boxchecked === Boxcheck.checked,
                    })}
                >
                    {
                        "VPs are utility passes that can be used to participate in campaigns."
                    }
                    <br />
                    {
                        "Please take note that VPs cannot be refunded, so please exercise caution before using VPs."
                    }
                </div>
            </div>

            <div className={style.dummy} />

            <div className={style.row}>
                <div
                    className={cx(style.icon, {
                        checked: props.boxchecked === Boxcheck.checked,
                    })}
                />
                <div
                    className={cx(style.content, {
                        checked: props.boxchecked === Boxcheck.checked,
                    })}
                >
                    {
                        "VPs can only be used in Valkyrie campaigns, and cannot be traded or transferred."
                    }
                </div>
            </div>
        </div>
    );
}
