import { StakeLpStakeManualMode } from "../StakeLp";
import style from "./StakeLpUnstake.module.scss";
import StakeLpUnstakeManual1 from "./StakeLpUnstakeManual1";
import StakeLpUnstakeManual2 from "./StakeLpUnstakeManual2";

export default function StakeLpUnstakeManual(props: {
    mode: StakeLpStakeManualMode;
    estimateParams?: ResponseLpEstimate;
    divideLpPressed: (amount: string) => void;
    unstakeLpPressed: (amount: string) => void;
}) {
    return (
        <div className={style.inner_container}>
            {props.mode === StakeLpStakeManualMode.step1 ? (
                <StakeLpUnstakeManual1
                    unstakeLpPressed={props.unstakeLpPressed}
                />
            ) : (
                <StakeLpUnstakeManual2
                    estimateParams={props.estimateParams}
                    divideLpPressed={props.divideLpPressed}
                />
            )}
        </div>
    );
}
