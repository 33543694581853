import { useState } from "react";
import style from "./GovernanceItem.module.scss";
import { PATHS } from "../../App";
import classNames from "classnames/bind";
import TitleLabel from "../common/Labels/TitleLabel";
import LineDummy from "../common/LineDummy/LineDummy";
import * as Utils from "../../libs/utils";
import { useEffect } from "react";
import {
    getGovernancePollType,
    getGovernancePollTypeName,
} from "../../libs/api/governance/execution";
import { getBlockTime } from "../../libs/api/api";

import VotingGraph from "../common/VotingGraph/VotingGraph";
import { Link } from "react-router-dom";
import { calcPollVotes } from "../../libs/api/governance/query";
import { divide } from "../../libs/Math";
import { GovStatus } from "../stake/Stake";

const cx = classNames.bind(style);

export default function GovernanceItem(props: {
    item: ResponsePollDetail;
    totalStaked: string;
    config?: ResponsePollConfig;
}) {
    const [endTime, setEndTime] = useState(0);

    useEffect(() => {
        getBlockTime(props.item.end_height)
            .then((endTime) => {
                setEndTime(endTime);
            })
            .catch((e) => {});
    }, [props.item]);

    const type = getGovernancePollType(props.item);
    const typeText = getGovernancePollTypeName(type);

    const id = props.item.id;
    const title = props.item.title;
    const votes = calcPollVotes(props.item, props.totalStaked);
    const quorum = props.config ? props.config.quorum * 100 : 0;

    const status =
        props.item.status === "in_progress"
            ? GovStatus.inProgress
            : props.item.status === "executed"
            ? GovStatus.executed
            : props.item.status === "passed"
            ? GovStatus.passed
            : props.item.status === "failed"
            ? GovStatus.failed
            : GovStatus.rejected;

    return (
        <Link
            className={cx(style.governance_polls_card, {
                expired: status !== GovStatus.inProgress,
            })}
            to={PATHS.governancePollDetail + id}
        >
            <div className={style.inner}>
                <div className={style.row0}>
                    <TitleLabel
                        text={typeText}
                        color={"#fff"}
                        fontWeight="light"
                    />
                    <TitleLabel
                        size={14}
                        text={"ID: #" + id}
                        color={"#fff"}
                        fontWeight="light"
                    />
                </div>
                <div className={style.row1}>
                    <TitleLabel
                        text="End Time:"
                        color={"#fff"}
                        fontWeight="bold"
                    />
                    &nbsp;
                    <TitleLabel
                        text={
                            endTime === 0
                                ? undefined
                                : Utils.dateFormat5(endTime * 1000)
                        }
                        fontWeight="light"
                        skeleton={{ width: 100 }}
                    />
                </div>
                <div className={style.row3}>
                    <div className={style.text}>{title}</div>
                </div>

                <div
                    className={cx(style.row2, {
                        inProgress: status === GovStatus.inProgress,
                        executed: status === GovStatus.executed,
                        passed: status === GovStatus.passed,
                        rejected: status === GovStatus.rejected,
                    })}
                >
                    {status === GovStatus.inProgress ? (
                        <div className={cx(style.icon, { inprogress: true })} />
                    ) : status === GovStatus.executed ? (
                        <div className={cx(style.icon, { executed: true })} />
                    ) : status === GovStatus.passed ? (
                        <div className={cx(style.icon, { passed: true })} />
                    ) : (
                        <div className={cx(style.icon, { rejected: true })} />
                    )}
                    {status === GovStatus.inProgress ? (
                        <div className={cx(style.text, { inprogress: true })}>
                            {"IN PROGRESS"}
                        </div>
                    ) : status === GovStatus.executed ? (
                        <div className={cx(style.text, { executed: true })}>
                            {"EXECUTED"}
                        </div>
                    ) : status === GovStatus.passed ? (
                        <div className={cx(style.text, { passed: true })}>
                            {"PASSED"}
                        </div>
                    ) : status === GovStatus.rejected ? (
                        <div className={cx(style.text, { rejected: true })}>
                            {"REJECTED"}
                        </div>
                    ) : (
                        <div className={cx(style.text, { rejected: true })}>
                            {"FAILED"}
                        </div>
                    )}
                </div>

                <div style={{ flex: 1 }} />

                <div className={style.voted_text}>
                    <div
                        className={cx(style.text, {
                            disabled: status !== GovStatus.inProgress,
                        })}
                    >
                        {"Voted " +
                            Utils.getPercentFormat(
                                divide(
                                    votes.rate.abstain +
                                        votes.rate.yes +
                                        votes.rate.no,
                                    100
                                )
                            ) +
                            "%"}
                    </div>
                </div>

                <div
                    className={cx(style.row4, {
                        disabled: status !== GovStatus.inProgress,
                    })}
                >
                    <VotingGraph
                        status={status}
                        info={votes}
                        quorum={quorum}
                        disabled={status !== GovStatus.inProgress}
                    />
                </div>
            </div>

            <div className={style.line_container}>
                <LineDummy horizontal={true} marginTop={15} marginBottom={0} />
            </div>

            <div className={style.buttons}>
                <div className={cx(style.button, "yes")}>
                    <div className={style.text1}>{"YES"}</div>
                    <div className={style.text2}>{votes.display.yes}</div>
                </div>
                <div className={style.dummy}>
                    <LineDummy horizontal={false} />
                </div>
                <div className={cx(style.button, "no")}>
                    <div className={style.text1}>{"NO"}</div>
                    {votes.display.no}
                </div>
                <div className={style.dummy}>
                    <LineDummy horizontal={false} />
                </div>
                <div className={cx(style.button, "abstain")}>
                    <div className={style.text1}>{"ABSTAIN"}</div>
                    {votes.display.abstain}
                </div>
            </div>

            {status !== GovStatus.inProgress ? (
                <div className={style.expired} />
            ) : (
                <div />
            )}
        </Link>
    );
}
