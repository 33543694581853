import style from "./CreateVpButton.module.scss";
import classNames from "classnames/bind";
import { useHistory } from "react-router-dom";
import { PATHS } from "../../../App";
const cx = classNames.bind(style);

export default function CreateVpButton(props: { rounded: boolean }) {
    const history = useHistory();

    return (
        <div
            className={cx(style.container, {
                rounded: props.rounded,
            })}
            onClick={() => {
                history.push(PATHS.vp);
            }}
        >
            <div className={style.title}>{"Create VP"}</div>
            <div className={style.buttonico_vp} />
        </div>
    );
}
