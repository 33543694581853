import style from "./DashboardChartHeader.module.scss";
import * as Utils from "../../../libs/utils";

import TitleLabel from "../Labels/TitleLabel";
import DashboardChartCell from "./DashboardChartCell";
import LineDummy from "../LineDummy/LineDummy";

export default function DashboardChartHeader(props: {
    meta: {
        title: string;
        tooltip: string;
        symbol: string;
        items: {
            title: string;
            color: string;
        }[];
    };
    value: {
        total: string;
        values: string[];
    };
    selected?: string;
}) {

    return (
        <div className={style.container}>
            <div className={style.row0}>
                <TitleLabel
                    text={props.meta.title}
                    color={"#fff"}
                    tooltip={props.meta.tooltip}
                    fontWeight={"light"}
                />
                <div style={{ height: "8px" }} />
                <TitleLabel
                    size={30}
                    text={Utils.getFormat(props.value.total)}
                    color={"white"}
                    symbol={props.meta.symbol}
                />
            </div>
            <LineDummy horizontal={true} marginTop={10} marginBottom={0} />

            <div className={style.row1}>
                <DashboardChartCell
                    color={props.meta.items[0].color}
                    title={props.meta.items[0].title}
                    body={props.value.values[0]}
                    symbol={props.meta.symbol}
                    select={props.meta.items[0].title === props.selected ? true : false}
                />

                <div className={style.dummy} />

                <DashboardChartCell
                    color={props.meta.items[1].color}
                    title={props.meta.items[1].title}
                    body={props.value.values[1]}
                    symbol={props.meta.symbol}
                    select={props.meta.items[1].title === props.selected ? true : false}
                />
            </div>

            <div className={style.row2}>
                <DashboardChartCell
                    color={props.meta.items[2].color}
                    title={props.meta.items[2].title}
                    body={props.value.values[2]}
                    symbol={props.meta.symbol}
                    select={props.meta.items[2].title === props.selected ? true : false}
                />

                <div className={style.dummy} />

                {props.value.values.length > 3 &&
                    props.meta.items.length > 3 ? (
                    <DashboardChartCell
                        color={props.meta.items[3].color}
                        title={props.meta.items[3].title}
                        body={props.value.values[3]}
                        symbol={props.meta.symbol}
                        select={props.meta.items[3].title === props.selected ? true : false}
                    />
                ) : (
                    <div style={{ flex: 1 }} />
                )}
            </div>
        </div>
    );
}
