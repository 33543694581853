import { environment, wasmQuery } from "../api";

export async function getVPRatio(
    address: string,
):Promise<string> {
    let response:ResponseVpTokenConfig = await wasmQuery(environment().contracts.vp_token, {"config":{}});

    let ratio = response.base_swap_ratio;

    let filtered = response.custom_swap_ratio.filter(item => {
        return item.address === address
    })

    if (filtered.length > 0) {
        ratio = filtered[0].ratio
    }

    return ratio
}