import { useState } from "react";
import style from "./ProducerSettingQualification.module.scss";
import { updateQualification } from "../../../../libs/api/campaign/execution";
import { addressValidate } from "../../../../libs/api/api";
import { PATHS } from "../../../../App";
import TxBoxView from "../../../common/TxBoxView/TxBoxView";
import PageHeader from "../../../common/PageHeader/PageHeader";
import { useEffect } from "react";

import { useWallet } from "@terra-money/wallet-provider";
import ProducerQualification from "../../Create/ProducerQualification";
import ProducerSettingFormFooter from "./ProducerSettingFormFooter";
import { walletState } from "../../../../libs/contexts";
import { useRecoilValue } from "recoil";

export default function ProducerSettingQualification(props: any) {
    const { post } = useWallet();
    const wallet = useRecoilValue(walletState);
    const [campaignAddress, setCampaignAddress] = useState("");
    const [summary, setSummary] = useState<CampaignSummary | undefined>(
        undefined
    );

    const [hash, setHash] = useState<HashResult | undefined>(undefined);

    const [contract, setContract] = useState("");
    const [desc, setDesc] = useState("");

    const [valid, setValid] = useState(false);

    useEffect(() => {
        const state: any = props.history.location.state;

        if (!state || !state.summary || !state.campaignAddress) {
            props.history.replace(PATHS.producer);
            return;
        }
        setCampaignAddress(state.campaignAddress);
        setSummary(state.summary);
    }, [props.history]);

    useEffect(() => {
        setValid(true);
        if (!contract || !addressValidate(contract)) {
            setValid(false);
        }
    }, [contract]);

    useEffect(() => {
        if (summary) {
            setContract(summary.config.qualifier ?? "");
            setDesc(summary.config.qualification_description ?? "");
        }
    }, [summary]);

    function savePressed() {
        setHash({
            type: "ProducerQualificationConnect",
            isPending: true,
        });

        updateQualification(
            wallet!.terraAddress,
            campaignAddress,
            contract ? contract : undefined,
            desc ? desc : undefined
        )
            .then((r) => {
                return post(r);
            })
            .then((response) => {
                setHash({
                    type: "ProducerQualificationConnect",
                    response: response,
                    redirect: PATHS.producerDetail + campaignAddress,
                });
            })
            .catch((error) => {
                setHash({
                    type: "ProducerQualificationConnect",
                    error: error,
                    redirect: undefined,
                });
            });
    }

    return (
        <div className={style.container}>
            <PageHeader title="Set Qualifier Contract" docs="" />

            <div className={style.campaign_title}>
                <div className={style.title1}>{"Campaign:"}</div>
                &nbsp;
                <div className={style.title2}>
                    {summary ? summary.config.title : ""}
                </div>
            </div>

            <TxBoxView
                hash={hash}
                clearHash={() => {
                    setHash(undefined);
                }}
                transparent={true}
            >
                <div className={style.inner}>
                    <ProducerQualification
                        contract={contract}
                        setContract={setContract}
                        desc={desc}
                        setDesc={setDesc}
                    />

                    <div style={{ height: "40px" }} />

                    <ProducerSettingFormFooter
                        enable={valid}
                        pressed={savePressed}
                    />
                </div>
            </TxBoxView>
        </div>
    );
}
