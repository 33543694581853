import style from "./CampaignActivation.module.scss";
import {
    InactiveButton,
    YellowButton,
} from "../../../common/NormalButton/NormalButton";
import { useHistory } from "react-router-dom";
import { PATHS } from "../../../../App";

export default function CampaignActivation(props: {
    summary: CampaignSummary;
    campaignAddress: String;
}) {
    const history = useHistory();
    const state = props.summary.state;
    const isActive = state.is_active;

    return (
        <div className={style.container}>
            <div className={style.dummy} />
            <div className={style.go_button}>
                {isActive ? (
                    <InactiveButton
                        title="Deactivate Campaign"
                        onClick={() => {
                            history.push(PATHS.producerSettingActivation, {
                                campaignAddress: props.campaignAddress,
                                summary: props.summary,
                            });
                        }}
                    />
                ) : (
                    <YellowButton
                        title="Activate Campaign"
                        disabled={false}
                        onClick={() => {
                            history.push(PATHS.producerSettingActivation, {
                                campaignAddress: props.campaignAddress,
                                summary: props.summary,
                            });
                        }}
                    />
                )}
            </div>
        </div>
    );
}
