import style from "./InputFieldDeposit.module.scss";
import classNames from "classnames/bind";

import TitleLabel from "../Labels/TitleLabel";
import InputField from "./InputField";

const cx = classNames.bind(style);

export default function InputFieldDeposit(props: {
    depositOn: boolean;
    setDepositOn: (v: boolean) => void;
    deposit: string;
    setDeposit: (v: string) => void;
    depositUnlockPeriodDay: string;
    setDepositUnlockPeriodDay: (v: string) => void;
}) {
    return (
        <div className={style.container}>
            <div className={style.row1_1}>
                <TitleLabel
                    text={"Deposit"}
                    symbol={"(optional)"}
                    size={20}
                    color={"white"}
                    fontWeight={"light"}
                />

                <div style={{ flex: 1 }} />

                <div className={style.switch_container}>
                    <div
                        className={cx(style.bg, { on: props.depositOn })}
                        onClick={() => {
                            props.setDepositOn(!props.depositOn);
                        }}
                    >
                        <div className={style.button} />
                    </div>
                </div>
            </div>

            <div style={{ marginTop: "20px" }} />

            <div className={style.row1_2}>
                <InputField
                    title=""
                    type={"amount"}
                    decimal={6}
                    value={props.deposit}
                    symbol={"VKR"}
                    onChanged={(v) => {
                        props.setDeposit(v.replaceAll(".", ""));
                    }}
                    disabled={!props.depositOn}
                />
                <div className={style.dummy} />
                <InputField
                    title=""
                    type={"amount"}
                    decimal={6}
                    value={props.depositUnlockPeriodDay}
                    symbol={"Days"}
                    placeholder={"Unlock Period"}
                    onChanged={(v) => {
                        props.setDepositUnlockPeriodDay(v.replaceAll(".", ""));
                    }}
                    disabled={!props.depositOn}
                />
            </div>
        </div>
    );
}
