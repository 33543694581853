import style from "./DashboardChartCell.module.scss";
import * as Utils from "../../../libs/utils";

import TitleLabel from "../Labels/TitleLabel";

export default function DashboardChartCell(props: {
    color: any;
    title: string;
    body: string;
    symbol: string;
    select?: boolean;
}) {
    return (
        <div className={style.container}>
            <div
                className={style.icon}
                style={{ backgroundColor: props.color }}
            />
            {props.select ? (
                <TitleLabel
                    text={props.title}
                    size={12}
                    fontWeight="light"
                    color={"#ffe646"}
                />
            ) : (
                <TitleLabel
                    text={props.title}
                    size={12}
                    fontWeight="light"
                    color={"#fff"}
                />
            )}
            <div className={style.dummy} />
            {props.select ? (
                <TitleLabel
                    text={Utils.getFormat(props.body)}
                    size={12}
                    fontWeight="bold"
                    color="#ffe646"
                    symbol={props.symbol}
                />
            ) : (
                <TitleLabel
                    text={Utils.getFormat(props.body)}
                    size={12}
                    fontWeight="bold"
                    color="#fff"
                    symbol={props.symbol}
                />
            )}

        </div>
    );
}
