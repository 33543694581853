import TitleLabel from "../Labels/TitleLabel";
import style from "./PageHeader.module.scss";

export default function PageHeader(props: {
    title: string;
    docs: string;
    subtitle?: string;
    vpburn?: boolean;
}) {
    return (
        <div className={style.container}>
            {props.subtitle ? (
                <TitleLabel
                    text={props.title + " : " + props.subtitle}
                    size={20}
                    fontWeight="light"
                    color={"white"}
                />
            ) : (
                <TitleLabel
                    text={props.title}
                    size={20}
                    fontWeight="light"
                    color={"white"}
                />
            )}

            {props.docs ? (
                <div>
                    {props.vpburn ? (
                        <a
                            className={style.docs_button}
                            href={
                                "https://medium.com/@valkyrie-protocol/90-day-permanent-burn-and-the-valkyrian-pass-vp-93d94ce54b9"
                            }
                            target="_blank"
                            rel={"noreferrer"}
                        >
                            <div className={style.icon} />
                            <div className={style.title}>{"What are VPs?"}</div>
                        </a>
                    ) : (
                        <a
                            className={style.docs_button}
                            href={
                                "https://docs.valkyrieprotocol.com" + props.docs
                            }
                            target="_blank"
                            rel={"noreferrer"}
                        >
                            <div className={style.icon} />
                            <div className={style.title}>{"docs"}</div>
                        </a>
                    )}
                </div>
            ) : (
                <div />
            )}
        </div>
    );
}
