import style from "./Warning.module.scss";
import classNames from "classnames/bind";

const cx = classNames.bind(style);

export default function Warning(props: {
    msg: string;
    borderHidden?: boolean;
}) {
    return (
        <div className={cx(style.container, { hidden: props.borderHidden })}>
            <div className={style.icon} />
            <div className={style.text}>{props.msg}</div>
        </div>
    );
}
