import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { getChainOptions, WalletProvider } from "@terra-money/wallet-provider";
import { RecoilRoot } from "recoil";

getChainOptions().then((chainOptions) => {
    ReactDOM.render(
        <RecoilRoot>
            <WalletProvider {...chainOptions}>
                <React.StrictMode>
                    <App />
                </React.StrictMode>
            </WalletProvider>
        </RecoilRoot>,
        document.getElementById("root")
    );
});

reportWebVitals();
