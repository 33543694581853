import style from "./MoreButton.module.scss";

export default function MoreButton(props: {
    isMore: boolean;
    onClick?: () => void;
}) {
    if (!props.isMore) {
        return <div />;
    }

    return (
        <div className={style.container}>
            <div className={style.button} onClick={props.onClick}>
                <div className={style.buttoncontent}>
                    <div className={style.buttonmore}>{"More"}</div>
                    <div className={style.buttonarrow} />
                </div>
            </div>
        </div>
    );
}

export function MoreButton2(props: { isMore: boolean; onClick: () => void }) {
    if (!props.isMore) {
        return <div />;
    }
    return (
        <div className={style.container2} onClick={props.onClick}>
            <div className={style.button}>
                <div className={style.buttontext}>{"More"}</div>
                <div className={style.button_ico} />
            </div>
        </div>
    );
}
