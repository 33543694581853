import style from "./SwitchButton.module.scss";
import classNames from "classnames/bind";

const cx = classNames.bind(style);

export default function SwitchButton(props: {
    titleLeft: string;
    titleRight: string;
    tabIndex: number;
    tabChanged: (index: number) => void;
}) {
    return (
        // <div className={style.container}>
        <div className={style.container}>
            <div
                className={cx(style.button, { selected: props.tabIndex === 0 })}
                onClick={() => {
                    props.tabChanged(0);
                }}
            >
                <div className={style.text}>{props.titleLeft}</div>
            </div>
            <div
                className={cx(style.button, { selected: props.tabIndex === 1 })}
                onClick={() => {
                    props.tabChanged(1);
                }}
            >
                <div className={style.text}>{props.titleRight}</div>
            </div>
        </div>
    );
}
