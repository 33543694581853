import style from "./ReceivablePopup.module.scss";
import * as Utils from "../../../../libs/utils";
import TitleLabel from "../../../common/Labels/TitleLabel";
import LineDummy from "../../../common/LineDummy/LineDummy";
import {
    divide,
    leftGreaterThanRight,
    minus,
    plus,
} from "../../../../libs/Math";
import { useHistory } from "react-router-dom";
import { PATHS } from "../../../../App";

export default function ReceivablePopup(props: {
    show: boolean;
    setShow: (v: boolean) => void;
    info: ReceivableReward;
    summary: CampaignSummary;
}) {
    const history = useHistory();
    const base_count = props.info.base_count;
    const percent_for_governance_staking = divide(
        props.info.percent_for_governance_staking,
        100
    );
    const referral_reward_sum = Utils.getDecimal(
        props.info.referral_reward_sum,
        true
    );
    const govStaked = Utils.getDecimal(props.info.govStaked, true);

    const isOver = leftGreaterThanRight(
        props.info.received,
        props.info.receivable
    )
        ? true
        : false;

    let moreNeedStake = "0";
    if (isOver) {
        const cap1 = minus(props.info.received, props.info.receivable);
        let rReward = "0";
        props.summary.reward.referral.amounts.forEach((amount) => {
            if (leftGreaterThanRight(amount, rReward)) {
                rReward = amount;
            }
        });

        const moreNeedToken = plus(cap1, rReward);

        moreNeedStake = divide(
            moreNeedToken,
            props.info.percent_for_governance_staking / 100
        );
    }

    return (
        <div className={style.container}>
            <div className={style.contents}>
                <div className={style.contents_title}>
                    <TitleLabel
                        size={18}
                        color={"white"}
                        fontWeight="medium"
                        text={"Sharing Reward Cap"}
                    />
                </div>
                <div className={style.contents_dummy} />
                <div className={style.text1}>
                    {"(Total Reward Scheme x " + base_count + ")"}
                    <br />
                    {"+"}
                    <br />
                    {"(Staking Balance x " +
                        percent_for_governance_staking +
                        ")"}
                </div>
                <div className={style.box}>
                    <div className={style.text1}>
                        {"(" + referral_reward_sum + " x " + base_count + ")"}
                        <br />
                        {"+"}
                        <br />
                        {"(" +
                            govStaked +
                            " x " +
                            percent_for_governance_staking +
                            ")"}
                    </div>
                    {/* <div style={{ width: "100%" }}>
                        <LineDummy horizontal={true} marginTop={20} />
                    </div> */}
                    <div className={style.boxdummy} />
                    <div className={style.text2}>
                        <div className={style.text2_amount}>
                            <TitleLabel
                                fontWeight={"medium"}
                                text={Utils.getDecimal(
                                    props.info.receivable,
                                    true
                                )}
                                size={16}
                                color={"#ffe646"}
                            />
                        </div>
                        <div className={style.text2_symbol}>
                            <TitleLabel
                                fontWeight={"medium"}
                                text={"VKR"}
                                size={12}
                                color={"#ffe646"}
                            />
                        </div>
                    </div>
                </div>

                {isOver ? (
                    <div className={style.box}>
                        <div className={style.text3}>
                            {"More than " +
                                Utils.getDecimal(moreNeedStake, true, 6) +
                                " VKR must be staked in order to be eligible to receive additional referral rewards."}
                        </div>
                    </div>
                ) : (
                    <div />
                )}

                <div className={style.buttons}>
                    <div
                        className={style.buttoncancel}
                        onClick={() => {
                            props.setShow(false);
                        }}
                    >
                        <TitleLabel
                            fontWeight={"medium"}
                            text={"Cancel"}
                            size={14}
                            color={"#000"}
                        />
                    </div>
                    <div
                        className={style.buttonstake}
                        onClick={() => {
                            history.push(PATHS.governance);
                        }}
                    >
                        <TitleLabel
                            fontWeight={"medium"}
                            text={"Stake"}
                            size={14}
                            color={"#000"}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}
