import style from "./GovernancePollDetail.module.scss";
import TitleLabel from "../common/Labels/TitleLabel";
import LineDummy from "../common/LineDummy/LineDummy";

import * as Utils from "../../libs/utils";
import { PATHS } from "../../App";
import {
    NormalButton,
    NormalButton2,
    StakeButton,
} from "../common/NormalButton/NormalButton";
import { MoreButton2 } from "../common/MoreButton/MoreButton";
import { useState } from "react";
import { useEffect } from "react";
import {
    getGovernancePollType,
    getGovernancePollTypeName,
} from "../../libs/api/governance/execution";
import {
    pollInfo,
    pollVoters,
    govStakerState,
} from "../../libs/api/governance/query";
import { useRef } from "react";

import VotingGraph from "../common/VotingGraph/VotingGraph";

import { getBlockTime } from "../../libs/api/api";
import { walletState } from "../../libs/contexts";
import { useRecoilValue } from "recoil";
import classNames from "classnames/bind";
import GovernanceRawMsgPopup from "./GovernanceRawMsgPopup";
import { fixed, multiply } from "../../libs/Math";
import { GovStatus } from "../stake/Stake";
const cx = classNames.bind(style);

export default function GovernancePollDetail(props: any) {
    const [info, setInfo] = useState<PollInfo | undefined>(undefined);
    const wallet = useRecoilValue(walletState);
    const [stakerInfo, setStakerInfo] = useState<
        ResponseGovStakerInfo | undefined
    >(undefined);

    const [showSeeRawMsgPopup, setShowSeeRawMsgPopup] = useState<
        ResponsePollExecution[] | undefined
    >(undefined);

    useEffect(() => {
        const body: any = document.querySelector("body");
        body.style.overflow =
            showSeeRawMsgPopup !== undefined ? "hidden" : "auto";
    }, [showSeeRawMsgPopup]);

    useEffect(() => {
        const pollId = props.match.params.pollId;

        if (!pollId) {
            props.history.replace(PATHS.governance);
            return;
        }

        pollInfo(pollId, wallet ? wallet.terraAddress : undefined)
            .then((r) => {
                setInfo(r);
            })
            .catch((e) => {
                props.history.replace(PATHS.governance);
            });
    }, [props.history, props.match.params, wallet]);

    useEffect(() => {
        if (wallet) {
            govStakerState(wallet.terraAddress)
                .then((r) => {
                    setStakerInfo(r);
                })
                .catch((e) => {});
        } else {
            setStakerInfo(undefined);
        }
    }, [wallet]);

    if (!info) {
        return <div />;
    } else {
        return (
            <div className={style.governance_detail_poll_container}>
                <InfoView
                    info={info}
                    stakerInfo={stakerInfo}
                    votePressed={() => {
                        props.history.push(PATHS.governanceVote, {
                            info: info,
                        });
                    }}
                    seeRawMsgsPressed={() => {
                        setShowSeeRawMsgPopup(info.info.executions);
                    }}
                />
                {showSeeRawMsgPopup !== undefined ? (
                    <GovernanceRawMsgPopup
                        info={showSeeRawMsgPopup}
                        hide={() => {
                            setShowSeeRawMsgPopup(undefined);
                        }}
                    />
                ) : (
                    <div />
                )}
            </div>
        );
    }
}

function InfoView(props: {
    info: PollInfo;
    stakerInfo?: ResponseGovStakerInfo;
    votePressed: () => void;
    seeRawMsgsPressed: () => void;
}) {
    const [endTime, setEndTime] = useState(0);

    useEffect(() => {
        getBlockTime(props.info.info.end_height)
            .then((endTime) => {
                setEndTime(endTime);
            })
            .catch((e) => {});
    }, [props.info]);

    const config = props.info.config;
    const info = props.info.info;
    const votes = props.info.votes;

    const type = getGovernancePollType(info);
    const typeText = getGovernancePollTypeName(type);

    const title = info.title;

    const creator = info.creator;
    const creatorShort =
        creator.substring(0, 6) +
        "..." +
        creator.substring(creator.length - 6, creator.length);

    const desc = info.description;
    const link = info.link;

    const status =
        info.status === "in_progress"
            ? GovStatus.inProgress
            : info.status === "executed"
            ? GovStatus.executed
            : info.status === "passed"
            ? GovStatus.passed
            : info.status === "failed"
            ? GovStatus.failed
            : GovStatus.rejected;

    const quorum = config.quorum * 100;

    let voted: any[] | undefined = props.stakerInfo
        ? props.stakerInfo.votes.filter((item) => {
              return item[0] === info.id;
          })
        : undefined;
    voted = voted && voted.length === 0 ? undefined : voted;
    const votedOption: "yes" | "no" | "abstain" | undefined = voted
        ? voted[0][1].option
        : undefined;

    return (
        <div style={{ width: "100%" }}>
            <div className={style.governance_detail_poll_header}>
                <div className={style.row0}>
                    <TitleLabel
                        text={typeText}
                        color={"#ffffff"}
                        fontWeight="light"
                        size={14}
                    />
                    <TitleLabel
                        text={"ID: #" + props.info.info.id}
                        color={"#ffffff"}
                        fontWeight="light"
                    />
                </div>
                <div className={style.row1}>
                    <TitleLabel
                        text="End Time:"
                        color={"#ffffff"}
                        fontWeight="bold"
                    />
                    &nbsp;
                    <div className={style.row1_1}>
                        <TitleLabel
                            text={
                                endTime === 0
                                    ? undefined
                                    : Utils.dateFormat5(endTime * 1000)
                            }
                            fontWeight="light"
                            skeleton={{ width: 100 }}
                        />
                    </div>
                </div>
                <div className={style.row3}>
                    <div className={style.text}>{title}</div>
                </div>
                <div
                    className={cx(style.row2, {
                        inProgress: status === GovStatus.inProgress,
                        executed: status === GovStatus.executed,
                        passed: status === GovStatus.passed,
                        rejected: status === GovStatus.rejected,
                    })}
                >
                    {status === GovStatus.inProgress ? (
                        <div className={cx(style.icon, { inprogress: true })} />
                    ) : status === GovStatus.executed ? (
                        <div className={cx(style.icon, { executed: true })} />
                    ) : status === GovStatus.passed ? (
                        <div className={cx(style.icon, { passed: true })} />
                    ) : (
                        <div className={cx(style.icon, { rejected: true })} />
                    )}
                    {status === GovStatus.inProgress ? (
                        <div className={cx(style.text, { inprogress: true })}>
                            {"IN PROGRESS"}
                        </div>
                    ) : status === GovStatus.executed ? (
                        <div className={cx(style.text, { executed: true })}>
                            {"EXECUTED"}
                        </div>
                    ) : status === GovStatus.passed ? (
                        <div className={cx(style.text, { passed: true })}>
                            {"PASSED"}
                        </div>
                    ) : status === GovStatus.failed ? (
                        <div className={cx(style.text, { rejected: true })}>
                            {"FAILED"}
                        </div>
                    ) : (
                        <div className={cx(style.text, { rejected: true })}>
                            {"REJECTED"}
                        </div>
                    )}
                </div>

                <div className={style.row5}>
                    <div>
                        <TitleLabel
                            size={14}
                            text={"Creator:"}
                            color="#ffffff"
                            fontWeight={"bold"}
                        />
                    </div>
                    &nbsp;
                    <div
                        className={style.text1}
                        onClick={() => {
                            Utils.showFinderAccount(creator);
                        }}
                    >
                        {creatorShort}
                    </div>
                    <div
                        className={style.text2}
                        onClick={() => {
                            Utils.showFinderAccount(creator);
                        }}
                    >
                        {creatorShort}
                    </div>
                </div>

                <div className={style.row7}>
                    <TitleLabel
                        text="Description"
                        color={"#ffffff"}
                        size={16}
                        fontWeight={"light"}
                    />
                    <div style={{ height: "10px" }} />
                    <div className={style.desc}>{desc}</div>
                    <div style={{ height: "30px" }} />
                    {link ? (
                        <TitleLabel
                            text="Link"
                            color={"#ffffff"}
                            fontWeight={"light"}
                            size={16}
                        />
                    ) : (
                        <div />
                    )}
                    {link ? <div style={{ height: "10px" }} /> : <div />}
                    {link ? (
                        <a
                            className={style.link}
                            href={link}
                            target={"_blank"}
                            rel="noreferrer"
                        >
                            {link!}
                        </a>
                    ) : (
                        <div />
                    )}
                </div>

                {props.info.info.executions ? (
                    <div className={style.row8}>
                        <div className={style.createbutton}>
                            <div
                                className={style.button}
                                onClick={props.seeRawMsgsPressed}
                            >
                                {"See Raw Msgs"}
                            </div>
                        </div>
                    </div>
                ) : (
                    <div />
                )}
            </div>

            <LineDummy horizontal={true} />

            <div className={style.governance_detail_vote_title}>
                <div className={style.text}>{"Vote Details"}</div>
            </div>

            <div className={style.governance_detail_vote_header}>
                <div className={style.row0}>
                    <TitleLabel text={"Voted"} color={"#ffffff"} size={14} />
                    <div style={{ height: "6px" }} />
                    <TitleLabel
                        text={
                            fixed(
                                multiply(
                                    (
                                        (votes.rate.yes +
                                            votes.rate.no +
                                            votes.rate.abstain) /
                                        100
                                    ).toString(),
                                    100
                                ),
                                2
                            ) + "%"
                        }
                        color={"#ffffff"}
                        size={24}
                    />
                    <div style={{ height: "10px" }} />
                    <VotingGraph status={status} info={votes} quorum={quorum} />
                </div>

                <div className={style.line_container}>
                    <LineDummy
                        horizontal={true}
                        marginTop={20}
                        marginBottom={30}
                    />
                </div>

                <div className={style.row1}>
                    <div className={style.button}>
                        <div className={cx(style.row11, { yes: true })}>
                            {"YES"}&nbsp;
                            {votes.display.yes}
                            {votedOption === "yes" ? (
                                <div className={style.voted_icon} />
                            ) : (
                                <div />
                            )}
                        </div>
                        <div className={style.row12}>
                            <TitleLabel
                                text={Utils.getDecimal(
                                    votes.value.yes.toString(),
                                    true
                                )}
                                symbol="VKR"
                                size={12}
                                color="#fff"
                                opacity={0.3}
                                fontWeight="light"
                            />
                        </div>
                    </div>
                    <div className={style.dummy1}>
                        <LineDummy horizontal={false} />
                    </div>
                    <div className={style.dummy2}>
                        <div className={style.line} />
                    </div>
                    <div className={style.button}>
                        <div className={cx(style.row11, { no: true })}>
                            {"NO"}&nbsp;
                            {votes.display.no}
                            {votedOption === "no" ? (
                                <div className={style.voted_icon} />
                            ) : (
                                <div />
                            )}
                        </div>
                        <div className={style.row12}>
                            <TitleLabel
                                text={Utils.getDecimal(
                                    votes.value.no.toString(),
                                    true
                                )}
                                symbol="VKR"
                                size={12}
                                color="#fff"
                                opacity={0.3}
                                fontWeight="light"
                            />
                        </div>
                    </div>
                    <div className={style.dummy1}>
                        <LineDummy horizontal={false} />
                    </div>
                    <div className={style.dummy2}>
                        <div className={style.line} />
                    </div>
                    <div className={style.button}>
                        <div className={cx(style.row11, { abstain: true })}>
                            {"ABSTAIN"}&nbsp;
                            {votes.display.abstain}
                            {votedOption === "abstain" ? (
                                <div className={style.voted_icon} />
                            ) : (
                                <div />
                            )}
                        </div>
                        <div className={style.row12}>
                            <TitleLabel
                                text={Utils.getDecimal(
                                    votes.value.abstain.toString(),
                                    true
                                )}
                                symbol="VKR"
                                size={12}
                                color="#fff"
                                opacity={0.3}
                                fontWeight="light"
                            />
                        </div>
                    </div>
                </div>

                {votedOption ||
                !props.stakerInfo ||
                status !== GovStatus.inProgress ? (
                    <div />
                ) : (
                    <div className={style.row2}>
                        <div className={style.button}>
                            <NormalButton2
                                title={"Vote"}
                                titleSize={"large"}
                                onClick={props.votePressed}
                            />
                        </div>
                    </div>
                )}
            </div>
            <Voters pollId={info.id} />
        </div>
    );
}

function Voters(props: { pollId: number }) {
    const isLoading = useRef(false);
    const [list, setList] = useState<ResponsePollVoter[]>([]);
    const [hasNext, setHasNext] = useState(true);

    useEffect(() => {
        load();
    }, []);

    function load() {
        if (isLoading.current) {
            return;
        }

        isLoading.current = true;

        const limit = 5;

        let startAfter: string | undefined = undefined;
        if (list.length === 0) {
            startAfter = undefined;
        } else {
            startAfter = list[list.length - 1].voter;
        }

        pollVoters(props.pollId, limit, startAfter)
            .then((r) => {
                let newList = list.slice();
                r.forEach((item) => {
                    newList.push(item);
                });
                setList(newList);

                setHasNext(limit === r.length);
                isLoading.current = false;
            })
            .catch((e) => {
                isLoading.current = false;
            });
    }

    if (list.length === 0) {
        return <div />;
    }

    return (
        <div className={style.governance_detail_voter}>
            <div className={style.row3}>
                <div className={style.header}>
                    <div className={style.col1}>
                        <TitleLabel
                            text="Voter"
                            color="#fff"
                            opacity={0.5}
                            fontWeight={"light"}
                        />
                    </div>
                    <div className={style.col2}>
                        <TitleLabel
                            text="Vote"
                            color="#fff"
                            opacity={0.5}
                            fontWeight={"light"}
                        />
                    </div>
                    <div className={style.col3}>
                        <TitleLabel
                            text="Amount"
                            color="#fff"
                            opacity={0.5}
                            fontWeight={"light"}
                        />
                    </div>
                </div>

                <div className={style.line_container}>
                    <LineDummy
                        horizontal={true}
                        marginTop={10}
                        marginBottom={0}
                    />
                </div>

                {list.map((item, index) => {
                    return <VoteItem item={item} key={index} />;
                })}

                {hasNext ? (
                    <div className={style.line_container}>
                        <LineDummy
                            horizontal={true}
                            marginTop={0}
                            marginBottom={0}
                        />
                    </div>
                ) : (
                    <div />
                )}

                <div className={style.more_button_container}>
                    <MoreButton2
                        isMore={hasNext}
                        onClick={() => {
                            load();
                        }}
                    />
                </div>

                {hasNext ? <div style={{ height: "40px" }} /> : <div />}
            </div>
        </div>
    );
}

function VoteItem(props: { item: ResponsePollVoter }) {
    const voter = props.item.voter;
    const voterShort =
        voter.substring(0, 6) +
        "..." +
        voter.substring(voter.length - 6, voter.length);

    return (
        <div className={style.item}>
            <div className={style.col1}>
                <div
                    className={style.item1}
                    onClick={() => {
                        Utils.showFinderAccount(voter);
                    }}
                >
                    {voterShort}
                </div>
                <div
                    className={style.item2}
                    onClick={() => {
                        Utils.showFinderAccount(voter);
                    }}
                >
                    {voterShort}
                </div>
            </div>
            <div className={style.col2}>
                <TitleLabel
                    text={props.item.option.toUpperCase()}
                    color={"#fff"}
                    fontWeight="light"
                />
            </div>
            <div className={style.col3}>
                <TitleLabel
                    text={Utils.getDecimal(props.item.amount, true)}
                    symbol="VKR"
                    color="#fff"
                    fontWeight="light"
                />
            </div>
        </div>
    );
}
