import style from "./BaseCampaignDetail.module.scss";

import { NormalButton } from "../../../common/NormalButton/NormalButton";

import { useEffect, useState } from "react";
import { campaignClaim } from "../../../../libs/api/campaign/execution";
import { calcRefRewardReceivableInfo } from "../../../../libs/api/campaign/query";
import * as Utils from "../../../../libs/utils";

import CampaignDetailPoolHistory from "../../../common/CampaignComponents/CampaignDetailPoolHistory";
import TitleLabel from "../../../common/Labels/TitleLabel";
import {
    divide,
    equalTo,
    isZero,
    leftGreaterThanRight,
    minus,
} from "../../../../libs/Math";
import TxBoxView from "../../../common/TxBoxView/TxBoxView";

import classNames from "classnames/bind";
import BaseCampaignGuide from "./Guide/BaseCampaignGuide";
import CampaignActivation from "../Common/CampaignActivation";
import CampaignDetailTitle from "../Common/CampaignDetailTitle";
import CampaignDescription from "../Common/CampaignDescription";
import settingIcon1 from "../../../../resources/ico-seting.svg";
import settingIcon2 from "../../../../resources/ico-seting-on.svg";
import poolIcon from "../../../../resources/ico-deposit-on.svg";
import { useHistory } from "react-router-dom";
import { PATHS } from "../../../../App";

const cx = classNames.bind(style);

export default function BaseCampaignDetail(props: {
    campaignAddress: string;
    summary: CampaignSummary;
}) {
    const history = useHistory();
    const [hash, setHash] = useState<HashResult | undefined>(undefined);

    return (
        <div className={style.wrap}>
            <div className={cx(style.container, { hash: hash !== undefined })}>
                <TxBoxView
                    hash={hash}
                    clearHash={() => {
                        setHash(undefined);
                    }}
                    transparent={true}
                >
                    <CampaignDetailTitle summary={props.summary} />
                    <div className={style.buttons}>
                        <div className={style.button}>
                            <NormalButton
                                title="Pool"
                                // icon={{
                                //     normal: poolIcon,
                                //     highlighted: poolIcon,
                                // }}
                                // textHighlight={true}
                                onClick={() => {
                                    history.push(
                                        PATHS.producerDepositWithdraw,
                                        {
                                            campaignAddress:
                                                props.campaignAddress,
                                            summary: props.summary,
                                        }
                                    );
                                }}
                            />
                        </div>
                        <div className={style.button}>
                            <NormalButton
                                title="Manager"
                                // icon={{
                                //     normal: settingIcon1,
                                //     highlighted: settingIcon2,
                                // }}
                                onClick={() => {
                                    history.push(PATHS.producerSetting, {
                                        campaignAddress: props.campaignAddress,
                                        summary: props.summary,
                                    });
                                }}
                            />
                        </div>
                    </div>

                    <BaseCampaignGuide
                        campaignAddress={props.campaignAddress}
                        summary={props.summary}
                    />
                    <CampaignActivation
                        summary={props.summary}
                        campaignAddress={props.campaignAddress}
                    />
                    <CampaignDescription
                        summary={props.summary}
                        isIpc={false}
                        title={"Description"}
                    />
                    <CampaignDetailPoolHistory
                        campaignAddress={props.campaignAddress}
                    />
                </TxBoxView>
            </div>
        </div>
    );
}

export function ParticipationTitle() {
    return (
        <div className={style.ptitle_container}>
            <div className={style.ptitle_info}>
                <TitleLabel
                    fontWeight={"light"}
                    text={"Participation Info"}
                    size={12}
                    color={"rgba(255,255,255,0.7)"}
                />
            </div>
            <div className={style.ptitle_locked}>
                <div className={style.ptitle_locked_title}>
                    <TitleLabel
                        fontWeight={"light"}
                        text={"Locked"}
                        size={12}
                        color={"rgba(255,255,255,0.7)"}
                    />
                </div>
                <div className={style.ptitle_locked_ico} />
            </div>
            <div className={style.ptitle_claimable}>
                <TitleLabel
                    fontWeight={"light"}
                    text={"Claimable"}
                    size={12}
                    color={"rgba(255,255,255,0.7)"}
                />
            </div>
        </div>
    );
}

export function ParticipationDetail(props: {
    title: string;
    tooltip?: string;
    claimable: string;
    locked: string;
    decimals: number;
    symbol: string;
    pressed?: () => void;
}) {
    return (
        <div className={style.pdetail_container}>
            <div className={style.pdetail_info}>
                <div
                    className={cx(style.pdetail_info_ico, {
                        sharing: props.title === "Sharing",
                        deposit: props.title === "Campaign Deposit",
                    })}
                />
                <div className={style.pdetail_info_title}>
                    <TitleLabel
                        fontWeight={"light"}
                        text={props.title}
                        size={16}
                        color={"#fff"}
                        tooltip={props.tooltip}
                    />
                </div>
            </div>
            <div className={style.pdetail_locked}>
                <div className={style.pdetail_locked_wrap}>
                    <div className={style.pdetail_locked_amount}>
                        <div
                            style={{
                                cursor: props.pressed ? "pointer" : "auto",
                            }}
                            onClick={() => {
                                if (props.pressed) {
                                    props.pressed();
                                }
                            }}
                        >
                            <TitleLabel
                                size={16}
                                text={Utils.getDecimal(
                                    props.locked,
                                    true,
                                    props.decimals
                                )}
                                color={"#f93660"}
                                underline={props.pressed ? true : false}
                                symbol={props.symbol}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className={style.pdetail_claimable}>
                <div className={style.pdetail_claimable_wrap}>
                    <div className={style.pdetail_claimable_amount}>
                        <TitleLabel
                            fontWeight={"light"}
                            text={Utils.getDecimal(
                                props.claimable,
                                true,
                                props.decimals
                            )}
                            size={16}
                            color={"#ffe646"}
                        />
                    </div>
                    <div className={style.pdetail_claimable_symbol}>
                        <TitleLabel
                            fontWeight={"medium"}
                            text={props.symbol}
                            size={12}
                            color={"#ffe646"}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}
