import { useEffect, useState } from "react";
import style from "./History.module.scss";

import PageHeader from "../common/PageHeader/PageHeader";

import { useCallback } from "react";
import { useHistory } from "react-router-dom";
import { PATHS } from "../../App";
import { useRef } from "react";
import HistoryItem from "../common/CampaignComponents/HistoryItem/HistoryItem";
import { MoreButton2 } from "../common/MoreButton/MoreButton";
import { txHistory } from "../../libs/api/rest";
import EmptyView from "../common/EmptyView/EmptyView";
import { useWallet } from "@terra-money/wallet-provider";

export default function History() {
    const history = useHistory();
    const { status, wallets } = useWallet();

    const loading = useRef(false);
    const cursor = useRef<string | undefined>(undefined);
    const [hasNext, setHasNext] = useState(true);
    const [list, setList] = useState<TxHistory[]>([]);

    useEffect(() => {
        if (!wallets) {
            history.replace(PATHS.home);
        } else {
            load(true);
        }
    }, [wallets, status]);

    const load = useCallback(
        (clear?: boolean) => {
            if (loading.current || !hasNext || !wallets) {
                return;
            }

            loading.current = true;

            txHistory(cursor.current, 5, undefined, wallets[0]!.terraAddress)
                .then((r) => {
                    let old: TxHistory[] = [];

                    if (!clear) {
                        old = list.slice();
                    }

                    r.items.forEach((item) => {
                        old.push(item);
                    });

                    cursor.current = r.nextCursor;
                    setHasNext(r.hasNext);
                    setList(old);
                    loading.current = false;
                })
                .catch((e) => {
                    loading.current = false;
                });
        },
        [list, hasNext, loading]
    );

    if (!wallets) {
        return <div />;
    }

    return (
        <div className={style.container}>
            <PageHeader title="Transaction History" docs="" />

            <div className={style.history_container}>
                {list.length === 0 && hasNext === false ? (
                    <EmptyView title={"No Existing Transaction History"} />
                ) : (
                    <div className={style.box}>
                        {list.map((item, index) => {
                            return <HistoryItem key={index} item={item} />;
                        })}

                        <MoreButton2
                            isMore={hasNext}
                            onClick={() => {
                                load();
                            }}
                        />

                        <div style={{ height: "40px" }} />
                    </div>
                )}
            </div>
        </div>
    );
}
