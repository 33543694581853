import { useState } from "react";
import style from "./ProducerSettingGeneral.module.scss";
import { PATHS } from "../../../../App";
import TxBoxView from "../../../common/TxBoxView/TxBoxView";
import PageHeader from "../../../common/PageHeader/PageHeader";

import { useEffect } from "react";
import activeIcon from "../../../../resources/ico-active.svg";
import inactiveIcon from "../../../../resources/ico-inactive.svg";
import prepareIcon from "../../../../resources/ico-preparing.svg";
import exIcon from "../../../../resources/ico-setting-ex.svg";

import { useWallet } from "@terra-money/wallet-provider";
import InputField from "../../../common/InputField/InputField";
import { updateCampaignActivate } from "../../../../libs/api/campaign/execution";
import ProducerSettingFormFooter from "./ProducerSettingFormFooter";
import { walletState } from "../../../../libs/contexts";
import { useRecoilValue } from "recoil";

export default function ProducerSettingActivation(props: any) {
    const { post } = useWallet();
    const wallet = useRecoilValue(walletState);
    const [campaignAddress, setCampaignAddress] = useState("");
    const [summary, setSummary] = useState<CampaignSummary | undefined>(
        undefined
    );

    const [hash, setHash] = useState<HashResult | undefined>(undefined);
    const [valid, setValid] = useState(false);

    useEffect(() => {
        const state: any = props.history.location.state;

        if (!state || !state.summary || !state.campaignAddress) {
            props.history.replace(PATHS.producer);
            return;
        }
        setCampaignAddress(state.campaignAddress);
        setSummary(state.summary);
    }, [props.history]);

    useEffect(() => {
        setValid(true);
    }, []);

    function savePressed() {
        if (summary && wallet) {
            setHash({
                type: "ChangeCampaignActivation",
                isPending: true,
            });

            updateCampaignActivate(
                wallet.terraAddress,
                campaignAddress,
                !summary.state.is_active
            )
                .then((r) => {
                    return post(r);
                })
                .then((response) => {
                    setHash({
                        type: "ChangeCampaignActivation",
                        response: response,
                        redirect: PATHS.producerDetail + campaignAddress,
                    });
                })
                .catch((error) => {
                    setHash({
                        type: "ChangeCampaignActivation",
                        error: error,
                        redirect: undefined,
                    });
                });
        }
    }

    return (
        <div className={style.container}>
            <PageHeader title="Set Campaign Status" docs="" />

            <div className={style.campaign_title}>
                <div className={style.title1}>{"Campaign:"}</div>
                &nbsp;
                <div className={style.title2}>
                    {summary ? summary.config.title : ""}
                </div>
            </div>

            <TxBoxView
                hash={hash}
                clearHash={() => {
                    setHash(undefined);
                }}
                transparent={true}
            >
                <div className={style.inner}>
                    <div style={{ display: "flex", position: "relative" }}>
                        <InputField
                            title="Current"
                            type="text"
                            disabled={true}
                            value={
                                summary && summary.state.is_pending
                                    ? "Prepare"
                                    : summary && summary.state.is_active
                                    ? "Active"
                                    : "Inactive"
                            }
                            onChanged={(v) => {}}
                        />
                        <img
                            style={{
                                position: "absolute",
                                right: "20px",
                                top: "50%",
                                transform: "translateY(-50%)",
                                width: "73px",
                                height: "25px",
                            }}
                            src={
                                summary && summary.state.is_pending
                                    ? prepareIcon
                                    : summary && summary.state.is_active
                                    ? activeIcon
                                    : inactiveIcon
                            }
                            alt=""
                        />
                    </div>
                    <img
                        src={exIcon}
                        alt=""
                        style={{
                            width: "50px",
                            height: "50px",
                            marginBottom: "20px",
                            alignSelf: "center",
                        }}
                    />
                    <div style={{ display: "flex", position: "relative" }}>
                        <InputField
                            title="Change"
                            type="text"
                            disabled={true}
                            value={
                                summary && summary.state.is_active
                                    ? "Inactive"
                                    : "Active"
                            }
                            onChanged={(v) => {}}
                        />
                        <img
                            style={{
                                position: "absolute",
                                right: "20px",
                                top: "50%",
                                transform: "translateY(-50%)",
                                width: "73px",
                                height: "25px",
                            }}
                            src={
                                summary && !summary.state.is_active
                                    ? activeIcon
                                    : inactiveIcon
                            }
                            alt=""
                        />
                    </div>

                    <div style={{ height: "40px" }} />

                    <ProducerSettingFormFooter
                        enable={valid}
                        pressed={savePressed}
                    />
                </div>
            </TxBoxView>
        </div>
    );
}
