import style from "./EmptyView.module.scss";

export default function EmptyView(props: { title: string }) {
    return (
        <div className={style.container}>
            <div className={style.icon} />
            <div className={style.text}>{props.title}</div>
            {/* <div className={style.text}>{props.title}</div> */}
        </div>
    );
}
