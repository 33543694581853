import style from "./ComboBox.module.scss";
import { useEffect } from "react";
import classNames from "classnames/bind";

const cx = classNames.bind(style);

export default function ComboBox(props: {
    showCombo: boolean;
    setShowCombo: (b: boolean) => void;
    selected: number;
    setSelected: (index: number) => void;
    list: { value: string; display: string }[];
}) {
    useEffect(() => {
        function handleClickOutside(event: any) {
            const obj = document.getElementById("combobox_container");
            if (obj && !(obj as any).contains(event.target)) {
                props.setShowCombo(false);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    useEffect(() => {
        props.setShowCombo(false);
    }, [props.selected]);

    if (!props.showCombo) {
        return <div />;
    }

    return (
        <div id="combobox_container" className={style.container}>
            {props.list.map((item, index) => {
                return (
                    <div
                        key={index}
                        className={cx(style.item, {
                            selected: props.selected === index,
                        })}
                        onClick={() => {
                            props.setSelected(index);
                        }}
                    >
                        {item.display}
                    </div>
                );
            })}
        </div>
    );
}
