import style from "./ProducerQualification.module.scss";
import InputField from "../../common/InputField/InputField";
import InputFieldMulti from "../../common/InputField/InputFieldMulti";
import { addressValidate } from "../../../libs/api/api";

export default function ProducerQualification(props: {
    contract: string;
    setContract: (v: string) => void;
    desc: string;
    setDesc: (v: string) => void;
}) {
    return (
        <div className={style.container}>
            <InputField
                title="Qualification Contract Address"
                type="text"
                value={props.contract}
                // tooltip={"wefew"}
                onChanged={props.setContract}
                error={
                    props.contract && !addressValidate(props.contract)
                        ? "Invalid address"
                        : undefined
                }
            />

            <InputFieldMulti
                title={"Description of Qualification for User"}
                placeholder={""}
                value={props.desc}
                // tooltip={"wefew"}
                onChanged={props.setDesc}
            />
        </div>
    );
}
