import style from "./LineDummy.module.scss";
import classNames from "classnames/bind";

const cx = classNames.bind(style);

export default function LineDummy(props: {
    horizontal: boolean;
    marginTop?: number;
    marginBottom?: number;
    marginLeft?: number;
    marginRight?: number;
    width?: number;
    height?: number;
}) {
    return (
        <div
            className={cx(style.container, {
                horizontal: props.horizontal,
            })}
            style={{
                width: props.width ? props.width + "px" : "auto",
                height: props.height ? props.height + "px" : "auto",
                marginLeft: props.marginLeft ? props.marginLeft + "px" : "0px",
                marginRight: props.marginRight
                    ? props.marginRight + "px"
                    : "0px",
                marginTop: props.marginTop ? props.marginTop + "px" : "0px",
                marginBottom: props.marginBottom
                    ? props.marginBottom + "px"
                    : "0px",
            }}
        >
            <div className={style.dummy1} />
            <div className={style.dummy2} />
        </div>
    );
}
