import style from "./LockedDepositListPopup.module.scss";

import { useEffect, useState } from "react";
import * as Utils from "../../../../libs/utils";
import TitleLabel from "../../../common/Labels/TitleLabel";
import LineDummy from "../../../common/LineDummy/LineDummy";
import ConfirmButton from "../../../common/ConfirmButton/ConfirmButton";
import { blockTime, latestBlock, tokenInfo } from "../../../../libs/api/api";

export default function LockedDepositListPopup(props: {
    show: number;
    setShow: (v: number) => void;
    summary: CampaignSummary;
}) {
    const [list, setList] = useState<
        {
            amount: string;
            block: number;
        }[]
    >([]);

    const [decimals, setDecimals] = useState(6);
    const [symbol, setSymbol] = useState("");

    useEffect(() => {
        if (props.show === 0) {
            if (props.summary.participation) {
                setList(
                    props.summary.participation.participation_reward.locked_list
                );
                setDecimals(props.summary.reward.participation.decimals);
                setSymbol(props.summary.reward.participation.symbol);
            }
        } else if (props.show === 1) {
            if (props.summary.deposit) {
                setList(props.summary.deposit.locked);
                setDecimals(6);
                setSymbol("VKR");
            }
        } else if (props.show === 2) {
            if (props.summary.participation) {
                setList(
                    props.summary.participation.referral_reward.locked_list
                );
                tokenInfo(props.summary.reward.referral.denom)
                    .then((r) => {
                        setDecimals(r.decimals);
                    })
                    .catch((e) => {});
                setSymbol(props.summary.reward.referral.symbol);
            }
        }
    }, [props.summary, props.show]);

    return (
        <div className={style.container}>
            <div className={style.contents}>
                <div className={style.contents_title}>
                    <TitleLabel
                        size={18}
                        color={"white"}
                        fontWeight="medium"
                        text={
                            props.show === 0
                                ? "Locked Participation Reward"
                                : props.show === 1
                                ? "Locked Campaign Deposit"
                                : "Locked Sharing Reward"
                        }
                    />
                </div>

                <div className={style.popdummy} />
                {/* <div className={style.claimable}>{"Claimable"}</div> */}
                <div className={style.list}>
                    {list.map((item, index) => {
                        return (
                            <PopupCard
                                key={index}
                                item={item}
                                decimals={decimals}
                                symbol={symbol}
                                // isLast={index === list.length - 1}
                                isLast={false}
                            />
                        );
                    })}
                    {list.length === 0 ? (
                        <div className={style.empty}>
                            {"No Existing Claimable"}
                        </div>
                    ) : (
                        <div />
                    )}
                </div>

                <div
                    className={style.checkbutton}
                    onClick={() => {
                        props.setShow(-1);
                    }}
                >
                    <TitleLabel
                        size={14}
                        color={"black"}
                        fontWeight="medium"
                        text={"Check"}
                    />
                </div>

                {/* <ConfirmButton
                    title={"Close"}
                    enable={true}
                    onClick={() => {
                        props.setShow(-1);
                    }}
                    marginTop={20}
                    marginBottom={0}
                /> */}
            </div>
        </div>
    );
}

function PopupCard(props: {
    item: {
        amount: string;
        block: number;
    };
    decimals: number;
    symbol: string;
    isLast: boolean;
}) {
    const [time, setTime] = useState<number | undefined>(undefined);

    useEffect(() => {
        latestBlock()
            .then((r) => {
                return blockTime(r, props.item.block);
            })
            .then((r) => {
                setTime(r);
            })
            .catch((e) => {});
    }, [props.item]);

    return (
        <div className={style.card}>
            <div className={style.item}>
                <div className={style.row1}>
                    {/* <TitleLabel
                        size={12}
                        color={"#786f64"}
                        fontWeight={"light"}
                        text={"Claimable:"}
                    />
                    &nbsp; */}
                    <TitleLabel
                        size={10}
                        color={"#c8c8c8"}
                        fontWeight={"light"}
                        text={time ? Utils.dateFormat5(time * 1000) : undefined}
                        skeleton={{ width: 100 }}
                    />
                </div>
                <div className={style.row2}>
                    <TitleLabel
                        size={12}
                        color={"#fff"}
                        fontWeight={"medium"}
                        symbol={props.symbol}
                        text={Utils.getDecimal(
                            props.item.amount,
                            true,
                            props.decimals
                        )}
                    />
                    <div className={style.icon} />
                </div>
            </div>
            {props.isLast ? <div /> : <div className={style.row2dummy} />}
        </div>
    );
}
