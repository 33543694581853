import style from "./TxErrorView.module.scss";
import { YellowButton } from "../NormalButton/NormalButton";

export default function TxErrorView(props: {
    message: string;
    pressed: () => void;
}) {
    return (
        <div className={style.error_box}>
            <div className={style.icon} />
            <div className={style.text1}>{"fail!"}</div>

            <div className={style.box}>
                <div className={style.text2}>{props.message}</div>
            </div>
            <div className={style.dummy}>
                <div className={style.dummy1} />
                <div className={style.dummy2} />
            </div>
            <div className={style.button}>
                <YellowButton title={"OK"} onClick={props.pressed} />
            </div>
        </div>
    );
}
