import style from "./ConfirmButton.module.scss";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { PATHS } from "../../../App";

import { useRecoilValue } from "recoil";
import { walletState } from "../../../libs/contexts";
import {
    BuyButton,
    SellButton,
    YellowButton,
} from "../NormalButton/NormalButton";

export default function ConfirmButton(props: {
    title: string;
    enable: boolean;
    onClick: () => void;
    isBuy?: boolean;
    marginTop?: number;
    marginBottom?: number;
    loading?: boolean;
}) {
    const history = useHistory();
    const wallet = useRecoilValue(walletState);
    const [connected, setConnected] = useState(true);

    useEffect(() => {
        if (wallet) {
            //connected
            setConnected(true);
        } else {
            setConnected(false);
        }
    }, [wallet]);

    return (
        <div
            className={style.wrapper}
            style={{
                marginTop: (props.marginTop ?? 30) + "px",
                marginBottom: (props.marginBottom ?? 40) + "px",
            }}
        >
            <div className={style.container}>
                {props.isBuy === true ? (
                    <BuyButton
                        title={connected ? props.title : "Connect Wallet"}
                        disabled={
                            connected ? !props.enable || props.loading : false
                        }
                        onClick={() => {
                            if (connected) {
                                if (props.enable && !props.loading) {
                                    props.onClick();
                                }
                            } else {
                                history.push(PATHS.connect);
                            }
                        }}
                    />
                ) : props.isBuy === false ? (
                    <SellButton
                        title={connected ? props.title : "Connect Wallet"}
                        disabled={
                            connected ? !props.enable || props.loading : false
                        }
                        onClick={() => {
                            if (connected) {
                                if (props.enable && !props.loading) {
                                    props.onClick();
                                }
                            } else {
                                history.push(PATHS.connect);
                            }
                        }}
                    />
                ) : (
                    <YellowButton
                        title={connected ? props.title : "Connect Wallet"}
                        disabled={
                            connected ? !props.enable || props.loading : false
                        }
                        loading={props.loading}
                        onClick={() => {
                            if (connected) {
                                if (props.enable && !props.loading) {
                                    props.onClick();
                                }
                            } else {
                                history.push(PATHS.connect);
                            }
                        }}
                    />
                )}
            </div>
        </div>
    );
}
