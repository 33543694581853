import style from "./GovernanceRawMsgPopup.module.scss";

import TitleLabel from "../common/Labels/TitleLabel";
import { YellowButton } from "../common/NormalButton/NormalButton";

export default function GovernanceRawMsgPopup(props: {
    info: ResponsePollExecution[];
    hide: () => void;
}) {
    return (
        <div className={style.container}>
            <div className={style.contents}>
                <TitleLabel
                    size={16}
                    color={"white"}
                    fontWeight="bold"
                    text={"Raw Msgs"}
                />
                <pre className={style.list}>
                    {JSON.stringify(props.info, null, 2)}
                </pre>

                <div className={style.button}>
                    <YellowButton title={"Close"} onClick={props.hide} />
                </div>
            </div>
        </div>
    );
}
