import "./CampaignDetailPoolHistory.scss";
import HistoryItem from "./HistoryItem/HistoryItem";
import * as Api from "../../../libs/api/rest";
import { useEffect, useState } from "react";
import { MoreButton2 } from "../MoreButton/MoreButton";
import { useRef } from "react";
import TitleLabel from "../Labels/TitleLabel";
import { useCallback } from "react";

export default function CampaignDetailPoolHistory(props: {
    campaignAddress: string;
}) {
    const loading = useRef(false);
    const cursor = useRef<string | undefined>(undefined);
    const [hasNext, setHasNext] = useState(true);

    const [list, setList] = useState<TxHistory[]>([]);

    const [endcheck, setEndcheck] = useState(false);

    const load = useCallback(
        (clear?: boolean) => {
            if (loading.current || !hasNext) {
                return;
            }

            loading.current = true;
            Api.txHistory(cursor.current, 5, "CAMPAIGN", props.campaignAddress)
                .then((r) => {
                    let old: TxHistory[] = [];

                    if (!clear) {
                        old = list.slice();
                    }

                    r.items.forEach((item) => {
                        old.push(item);
                    });

                    cursor.current = r.nextCursor;
                    setHasNext(r.hasNext);
                    setList(old);

                    if (cursor.current === undefined) {
                        setEndcheck(true);
                    }

                    loading.current = false;
                })
                .catch((e) => {
                    loading.current = false;
                });
        },
        [list]
    );

    useEffect(() => {
        load(true);
        // }, [filter]);
    }, []);

    if (list.length === 0) {
        return <div />;
    }

    return (
        <div className="campaign_detail_pool_history_container">
            <div className="title_container">
                <TitleLabel
                    size={20}
                    color={"#fff"}
                    text="Campaign Contract Transaction"
                    fontWeight="light"
                />
                <div className="dummy" />
            </div>

            <div className="box">
                {list.map((item, index) => {
                    return <HistoryItem key={index} item={item} />;
                })}
                <MoreButton2
                    isMore={hasNext}
                    onClick={() => {
                        load();
                    }}
                />

                {endcheck ? (
                    <div style={{ height: 0 }} />
                ) : (
                    <div style={{ height: "40px" }} />
                )}
            </div>
        </div>
    );
}
