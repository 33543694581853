import style from "./ConditionLabel.module.scss";
import classNames from "classnames/bind";

const cx = classNames.bind(style);

export function ConditionLabel(props: { title: string; passed: boolean }) {
    return (
        <div className={style.container}>
            <div className={cx(style.icon, { passed: props.passed })} />
            <div className={cx(style.text, { passed: props.passed })}>
                {props.title}
            </div>
        </div>
    );
}
