import { useState } from "react";
import style from "./GovernanceCreateForm.module.scss";

import PageHeader from "../../common/PageHeader/PageHeader";
import TxBoxView from "../../common/TxBoxView/TxBoxView";
import { useEffect } from "react";

import GovCreateForm2 from "./GovCreateForm2";
import GovCreateForm3 from "./GovCreateForm3";
import GovCreateForm5 from "./GovCreateForm5";
import GovCreateForm6 from "./GovCreateForm6";
import { getGovernancePollTypeName } from "../../../libs/api/governance/execution";
import { pollConfig } from "../../../libs/api/governance/query";
import { PATHS } from "../../../App";

export enum GovCreateType {
    none,

    ModifyCampaignParameter,
    ModifyGovParameter,
    CommunityGrants,
    Text,
}

export default function GovernanceCreateForm(props: any) {
    const [hash, setHash] = useState<HashResult | undefined>(undefined);
    const [type, setType] = useState<GovCreateType | undefined>(undefined);
    const [subtitle, setSubtitle] = useState("");
    const [state, setState] = useState<any>(undefined);
    const [deposit, setDeposit] = useState("0");

    useEffect(() => {
        if (
            !props.history.location.state ||
            !(props.history.location.state as any).type
        ) {
            props.history.replace(PATHS.home);
            return;
        }

        const type = (props.history.location.state as any).type;
        setType(type);
        setState(props.history.location.state);

        pollConfig()
            .then((r) => {
                setDeposit(r.proposal_deposit);
            })
            .catch((e) => {});
    }, [props.history]);

    useEffect(() => {
        if (type) {
            setSubtitle(getGovernancePollTypeName(type));
        }
    }, [type]);

    return (
        <div className={style.governance_create_form_container}>
            <PageHeader title={"Create Poll: " + subtitle} docs={""} />

            <TxBoxView
                hash={hash}
                clearHash={() => {
                    setHash(undefined);
                }}
                transparent={true}
            >
                {type ? (
                    <div className={style.parse_container}>
                        <Parse
                            type={type!}
                            state={state}
                            deposit={deposit}
                            setHash={setHash}
                        />
                    </div>
                ) : (
                    <div />
                )}
            </TxBoxView>
        </div>
    );
}

function Parse(props: {
    type: GovCreateType;
    state: any;
    deposit: string;
    setHash: (h: HashResult) => void;
}) {
    if (props.type === GovCreateType.ModifyCampaignParameter) {
        return (
            <GovCreateForm2 setHash={props.setHash} deposit={props.deposit} />
        );
    } else if (props.type === GovCreateType.ModifyGovParameter) {
        return (
            <GovCreateForm3 setHash={props.setHash} deposit={props.deposit} />
        );
    } else if (props.type === GovCreateType.CommunityGrants) {
        return (
            <GovCreateForm5 setHash={props.setHash} deposit={props.deposit} />
        );
    } else if (props.type === GovCreateType.Text) {
        return (
            <GovCreateForm6 setHash={props.setHash} deposit={props.deposit} />
        );
    }

    return <div />;
}
