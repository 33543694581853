import style from "./CampaignParticipationManual.module.scss";

import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { walletState } from "../../../../../libs/contexts";
import InputFieldPaste from "../../../../common/InputField/InputFieldPaste";

export default function CampaignParticipationManual(props: {
    campaignAddress: string;
    summary: CampaignSummary;
    setTxPassed: (v: boolean) => void;
    txHash: string;
    setTxHash: (t: string) => void;
    setHashResult: (v: TxQualificationInfo | undefined) => void;
}) {
    const wallet = useRecoilValue(walletState);

    const [signature, setSignature] = useState("");
    const [referrer, setReferrer] = useState("");
    const [ruleID, setRuleID] = useState("");

    useEffect(() => {
        if (!wallet) {
            props.setHashResult(undefined);
        }
    }, [wallet]);

    useEffect(() => {
        props.setTxPassed(true);
    }, []);

    useEffect(() => {
        let hashResult: TxQualificationInfo = {
            status: "SUCCESS",
            reason: undefined,
            participationCode: "",
            campaign: props.campaignAddress,
            actor: wallet ? wallet.terraAddress : "",
            referrer: referrer ? referrer : undefined,
            encodedSignature: signature,
            ruleId: ruleID,
            transactionHash: props.txHash,
        };
        props.setHashResult(hashResult);
        props.setTxPassed(true);
    }, [signature, referrer, ruleID, props.txHash, props.campaignAddress]);

    return (
        <div className={style.container}>
            <div style={{ height: "6px" }} />

            <InputFieldPaste
                title={"Tx"}
                value={props.txHash}
                onChanged={props.setTxHash}
            />

            <InputFieldPaste
                title={"Signature"}
                value={signature}
                onChanged={setSignature}
            />

            <InputFieldPaste
                title={"Referrer"}
                value={referrer}
                onChanged={setReferrer}
            />

            <InputFieldPaste
                title={"Rule ID"}
                value={ruleID}
                onChanged={setRuleID}
            />
        </div>
    );
}
