import { useEffect, useRef, useState } from "react";
import style from "./StakePolls.module.scss";
import { useRecoilValue } from "recoil";
import { walletState } from "../../libs/contexts";
import { PATHS } from "../../App";
import TitleLabel from "../common/Labels/TitleLabel";
import LineDummy from "../common/LineDummy/LineDummy";
import ComboView from "../common/ComboView/ComboView";
import EmptyView from "../common/EmptyView/EmptyView";
import GovernanceItem from "../Governance/GovernanceItem";
import MoreButton from "../common/MoreButton/MoreButton";
import {
    governancePollList,
    pollConfig,
    govState,
} from "../../libs/api/governance/query";
import { WebLinks } from "../../libs/api/api";
import * as Utils from "../../libs/utils";
import { useHistory } from "react-router-dom";

export default function StakePolls() {
    const history = useHistory();

    const filters = [
        {
            value: "all",
            display: "All",
        },
        {
            value: "in_progress",
            display: "In Progress",
        },
        {
            value: "passed",
            display: "Passed",
        },
        {
            value: "rejected",
            display: "Rejected",
        },
        {
            value: "executed",
            display: "Executed",
        },
        {
            value: "failed",
            display: "Failed",
        },
    ];
    const [filter, setFilter] = useState(0);

    return (
        <div className={style.container}>
            <div className={style.line_container}>
                <LineDummy horizontal={true} marginTop={40} />
            </div>

            <div className={style.governance_polls_title_container}>
                <div className={style.top_container}>
                    <div className={style.title}>
                        <TitleLabel
                            text="Polls"
                            tooltip="Staked VKR can be used as voting power in polls that are currently in progress"
                            color="#ffffff"
                            fontWeight={"light"}
                            size={20}
                        />
                    </div>
                    <ComboView
                        list={filters}
                        setSelected={setFilter}
                        selected={filter}
                    />
                </div>
                <div className={style.dummy} />

                <div className={style.bottom_container}>
                    <div className={style.buttons}>
                        <div className={style.button_container}>
                            <div className={style.createbutton}>
                                <div
                                    className={style.button}
                                    onClick={() => {
                                        Utils.showUrl(WebLinks.forum);
                                    }}
                                >
                                    {"Join Forum"}
                                </div>
                            </div>
                        </div>
                        <div className={style.dummy2} />
                        <div className={style.button_container}>
                            <div className={style.createbutton}>
                                <div
                                    className={style.poll_button}
                                    onClick={() => {
                                        history.push(PATHS.governanceCreate);
                                    }}
                                >
                                    {"+ Create Poll"}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <List filter={filters[filter]} />
        </div>
    );
}

function List(props: { filter: { value: string; display: string } }) {
    const wallet = useRecoilValue(walletState);
    const isLoading = useRef(false);
    const [config, setConfig] = useState<ResponsePollConfig | undefined>(
        undefined
    );
    const [totalStaked, setTotalStaked] = useState("0");
    const [list, setList] = useState<ResponsePollDetail[]>([]);
    const [hasNext, setHasNext] = useState(true);

    useEffect(() => {
        pollConfig()
            .then((r) => {
                setConfig(r);
            })
            .catch((e) => {});
    }, []);

    useEffect(() => {
        govState(wallet ? wallet.terraAddress : undefined)
            .then((r) => {
                setTotalStaked(r.totalStaked);
            })
            .catch((e) => {});
    }, [wallet]);

    useEffect(() => {
        load(true);
    }, [props.filter]);

    function load(clear?: boolean) {
        if (isLoading.current) {
            return;
        }

        isLoading.current = true;

        const limit = 6;

        let startAfter: number | undefined = undefined;
        if (clear || list.length === 0) {
            startAfter = undefined;
        } else {
            startAfter = list[list.length - 1].id;
        }

        let filter: string | undefined =
            props.filter.value === "all" ? undefined : props.filter.value;

        governancePollList(limit, startAfter, filter)
            .then((r) => {
                if (clear) {
                    setList(r);
                } else {
                    let newList = list.slice();
                    r.forEach((item) => {
                        newList.push(item);
                    });
                    setList(newList);
                }

                setHasNext(limit === r.length);
                isLoading.current = false;
            })
            .catch((e) => {
                setHasNext(false);
                isLoading.current = false;
            });
    }

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                paddingTop: "16px",
            }}
        >
            {list.length === 0 && !hasNext ? (
                <EmptyView title="No Existing Polls" />
            ) : (
                <div className={style.governance_polls_cards}>
                    {list.map((item, index) => {
                        return (
                            <GovernanceItem
                                key={index}
                                item={item}
                                totalStaked={totalStaked}
                                config={config}
                            />
                        );
                    })}
                </div>
            )}

            <MoreButton
                isMore={list.length > 0 && hasNext}
                onClick={() => {
                    load();
                }}
            />
        </div>
    );
}
