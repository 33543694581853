import style from "./StepBar2.module.scss";
import classNames from "classnames/bind";

const cx = classNames.bind(style);

export default function StepBar2(props: {
    title1: string;
    title2: string;
    selected: number;
    setSelected: (index: number) => void;
}) {
    return (
        <div className={style.container}>
            <div
                className={cx(style.button, {
                    left: true,
                    selected: props.selected === 0,
                })}
                onClick={() => {
                    props.setSelected(0);
                }}
            >
                <div className={style.text}>{props.title1}</div>
                <div className={style.bar} />
            </div>
            <div className={style.icon} />
            <div
                className={cx(style.button, {
                    right: true,
                    selected: props.selected === 1,
                })}
                onClick={() => {
                    props.setSelected(1);
                }}
            >
                <div className={style.text}>{props.title2}</div>
                <div className={style.bar} />
            </div>
        </div>
    );
}
