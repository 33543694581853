import TitleLabel from "../../../common/Labels/TitleLabel";
import style from "./CampaignDescription.module.scss";

export default function CampaignDescription(props: {
    summary: CampaignSummary;
    isIpc: boolean;
    title: string;
}) {
    if (props.isIpc) {
        return (
            <div className={style.container}>
                <TitleLabel
                    fontWeight={"bold"}
                    text={props.title}
                    size={28}
                    color={"#fff"}
                />

                <pre className={style.description}>
                    {props.summary.config.description}
                </pre>
            </div>
        );
    } else {
        return (
            <div className={style.container}>
                <TitleLabel
                    fontWeight={"light"}
                    text={props.title}
                    size={20}
                    color={"#fff"}
                />

                <pre className={style.description}>
                    {props.summary.config.description}
                </pre>
            </div>
        );
    }
}
