import style from "./ConditionValue.module.scss";
import classNames from "classnames/bind";
import { useHistory } from "react-router-dom";
import { PATHS } from "../../../../../App";
import { useEffect, useState } from "react";
import { getDecimal } from "../../../../../libs/utils";

const cx = classNames.bind(style);

export enum ConditionType {
    deposit,
    participation,
    vp,
}

export function ConditionValue(props: {
    passed: boolean;
    type: ConditionType;
    summary: CampaignSummary;
}) {
    const history = useHistory();

    const [value, setValue] = useState("");
    const [value2, setValue2] = useState("");

    useEffect(() => {
        if (props.type === ConditionType.deposit) {
            const config = props.summary.config;
            setValue(getDecimal(config.deposit.amount, true));
            setValue2(config.deposit_lock_period_day.toString());
        } else if (props.type === ConditionType.participation) {
            const participation = props.summary.participation;
            let count = participation
                ? participation.participation_reward.count
                : 0;
            setValue(count.toString());
        } else if (props.type === ConditionType.vp) {
            const config = props.summary.config;
            setValue(getDecimal(config.vp_burn_amount, true));
        }
    }, [props.type, props.summary]);

    return (
        <div className={style.container}>
            <div className={cx(style.text, { passed: props.passed })}>
                {value}
            </div>
            {props.type === ConditionType.deposit ? (
                <div className={style.suffix_deposit}>
                    <div className={style.row1}>{"VKR"}</div>
                    <div className={style.row2}>
                        <div className={style.text}>
                            {(value2 ?? "0") + " Days"}
                        </div>
                        <div className={style.icon} />
                    </div>
                </div>
            ) : props.type === ConditionType.participation ? (
                <div className={style.suffix_participation}>
                    {"Participants"}
                </div>
            ) : (
                <div className={style.suffix_vp}>
                    <div className={style.col1}>{"VP"}</div>
                    <div className={style.col2} />
                    <div
                        className={style.col3}
                        onClick={() => {
                            history.push(PATHS.vp);
                        }}
                    >
                        <div className={style.icon} />
                        <div className={style.text}>{"Create VP"}</div>
                    </div>
                </div>
            )}
        </div>
    );
}
