import { environment, wasmExecutes } from "../api";

import { GovCreateType } from "../../../views/Governance/CreateForm/GovernanceCreateForm";
import { fixed } from "../../Math";
import { CreateTxOptions } from "@terra-money/terra.js";
import { pollConfig } from "./query";

export async function poll2(
    address: string,
    info: { title: string; desc: string; link?: string },
    campaignCodeId?: number,
    campaignDepositFee?: string,
    rewardRatio?: string,
    campaignWithdrawFeeRate?: string,
    campaignDeactivatePeroid?: number,
    baseCount?: number,
    percent_for_governance_staking?: number
): Promise<CreateTxOptions> {
    const msgs = [];

    msgs.push({
        order: 0,
        contract: environment().contracts.campaignManager,
        base64EncodedMsg: btoa(
            JSON.stringify({
                update_config: {
                    code_id: campaignCodeId,
                    add_pool_fee_rate: campaignDepositFee,
                    remove_pool_fee_rate: campaignWithdrawFeeRate,
                    deactivate_period: campaignDeactivatePeroid,
                    add_pool_min_referral_reward_rate: rewardRatio,
                },
            })
        ),
    });

    if (baseCount || percent_for_governance_staking) {
        msgs.push({
            order: 0,
            contract: environment().contracts.campaignManager,
            base64EncodedMsg: btoa(
                JSON.stringify({
                    update_referral_reward_limit_option: {
                        base_count: baseCount,
                        percent_for_governance_staking:
                            percent_for_governance_staking,
                    },
                })
            ),
        });
    }

    return await createPoll(address, info, msgs);
}

export async function poll3(
    address: string,
    info: { title: string; desc: string; link?: string },
    quorum?: number,
    threshold?: number,
    voting_period?: number,
    snapshot_period?: number,
    timelock_period?: number,
    proposal_deposit?: string,
    ticket_period?: number,
    ticket_amount?: string
): Promise<CreateTxOptions> {
    let msgs: any[] = [];
    msgs.push({
        update_poll_config: {
            quorum: quorum ? quorum.toString() : null,
            threshold: threshold ? threshold.toString() : null,
            voting_period: voting_period ? voting_period : null,
            execution_delay_period: timelock_period ? timelock_period : null,
            snapshot_period: snapshot_period ? snapshot_period : null,
            proposal_deposit: proposal_deposit ? proposal_deposit : null,
        },
    });

    if (ticket_period || ticket_amount) {
        msgs.push({
            update_ticket_config: {
                ticket_distribute_period: ticket_period,
                ticket_distribute_amount: ticket_amount,
            },
        });
    }

    return await createPoll(
        address,
        info,
        msgs.map((item, index) => {
            return {
                order: index,
                contract: environment().contracts.governance,
                base64EncodedMsg: btoa(JSON.stringify(item)),
            };
        })
    );
}

export async function poll5(
    address: string,
    info: { title: string; desc: string; link?: string },
    recipient: string,
    amount: string
): Promise<CreateTxOptions> {
    const msg = {
        transfer: {
            recipient: recipient,
            amount: amount,
        },
    };

    return await createPoll(address, info, [
        {
            order: 0,
            contract: environment().contracts.token,
            base64EncodedMsg: btoa(JSON.stringify(msg)),
        },
    ]);
}

export async function poll6(
    address: string,
    info: {
        title: string;
        desc: string;
        link?: string;
    }
): Promise<CreateTxOptions> {
    return await createPoll(address, info, []);
}

export async function createPoll(
    address: string,
    info: { title: string; desc: string; link?: string },
    msgs?: { order: number; contract: string; base64EncodedMsg: string }[]
): Promise<CreateTxOptions> {
    const config = await pollConfig();

    const subMsg = {
        create_poll: {
            title: info.title,
            description: info.desc,
            link: info.link ? info.link : undefined,
            executions: msgs
                ? msgs.map((item) => {
                      return {
                          order: item.order,
                          contract: item.contract,
                          msg: item.base64EncodedMsg,
                      };
                  })
                : undefined,
        },
    };

    const exe1: WasmExecute = {
        contract: environment().contracts.token,
        msg: {
            send: {
                contract: environment().contracts.governance,
                amount: config.proposal_deposit,
                msg: btoa(JSON.stringify(subMsg)),
            },
        },
        coin: undefined,
    };

    return await wasmExecutes(address, [exe1]);
}

export async function pollVote(
    address: string,
    id: number,
    vote: "yes" | "no" | "abstain",
    amount: string
): Promise<CreateTxOptions> {
    const exe: WasmExecute = {
        contract: environment().contracts.governance,
        msg: {
            cast_vote: {
                poll_id: id,
                vote: vote,
                amount: amount,
            },
        },
        coin: undefined,
    };

    return wasmExecutes(address, [exe]);
}

export function getGovernancePollType(info: ResponsePollDetail): GovCreateType {
    if (info.executions) {
        if (info.executions.length > 0) {
            const msg = JSON.parse(atob(info.executions[0].msg));

            if (msg["transfer"]) {
                return GovCreateType.CommunityGrants;
            } else if (msg["update_poll_config"]) {
                return GovCreateType.ModifyGovParameter;
            } else if (msg["update_config"]) {
                return GovCreateType.ModifyCampaignParameter;
            }
        }
    }

    return GovCreateType.Text;
}

export function getGovernancePollTypeName(type: GovCreateType): string {
    if (type === GovCreateType.ModifyCampaignParameter) {
        return "Modify Campaign Parameters";
    } else if (type === GovCreateType.ModifyGovParameter) {
        return "Modify Governance Parameters";
    } else if (type === GovCreateType.CommunityGrants) {
        return "Community Grants";
    } else if (type === GovCreateType.Text) {
        return "Text Proposal";
    }

    return "";
}

export async function endPoll(address: string, pollId: number) {
    const exe: WasmExecute = {
        contract: environment().contracts.governance,
        msg: {
            end_poll: {
                poll_id: pollId,
            },
        },
        coin: undefined,
    };

    return await wasmExecutes(address, [exe]);
}

export async function executePoll(address: string, pollId: number) {
    const exe: WasmExecute = {
        contract: environment().contracts.governance,
        msg: {
            execute_poll: {
                poll_id: pollId,
            },
        },
        coin: undefined,
    };

    return await wasmExecutes(address, [exe]);
}

export async function stakeGov(
    address: string,
    amount: string
): Promise<CreateTxOptions> {
    const subMsg = {
        stake_governance_token: {},
    };

    const exe: WasmExecute = {
        contract: environment().contracts.token,
        msg: {
            send: {
                contract: environment().contracts.governance,
                amount: fixed(amount, 0),
                msg: btoa(JSON.stringify(subMsg)),
            },
        },
        coin: undefined,
    };

    return await wasmExecutes(address, [exe]);
}

export async function unstakeGov(
    address: string,
    amount: string
): Promise<CreateTxOptions> {
    const exe: WasmExecute = {
        contract: environment().contracts.governance,
        msg: {
            unstake_governance_token: {
                amount: fixed(amount, 0),
            },
        },
        coin: undefined,
    };

    return await wasmExecutes(address, [exe]);
}

export async function ticketClaim(address: string): Promise<CreateTxOptions> {
    const exe: WasmExecute = {
        contract: environment().contracts.governance,
        msg: {
            ticket_claim: {},
        },
        coin: undefined,
    };

    return await wasmExecutes(address, [exe]);
}
