import { GovStatus } from "../../stake/Stake";
import style from "./VotingGraph.module.scss";

export default function VotingGraph(props: {
    status: GovStatus;
    info: PollVotesInfo;
    quorum: number;
    disabled?: boolean;
}) {
    const yes = props.info.rate.yes;
    const no = props.info.rate.no;
    const abstain = props.info.rate.abstain;
    const sum = yes + no + abstain;
    const total = 100;

    return (
        <div className={style.container}>
            <div className={style.voted_bar}>
                <div className={style.agree} style={{ flex: yes }} />
                <div className={style.disagree} style={{ flex: no }} />
                <div className={style.abstain} style={{ flex: abstain }} />
                <div
                    style={{
                        flex: total - sum,
                    }}
                />
            </div>
            <div className={style.quorum_line}>
                <div style={{ flex: props.quorum }} />
                <div className={style.line} />
                <div style={{ flex: total - props.quorum }} />
            </div>
            <div className={style.quorum_bar}>
                <div
                    className={style.text}
                    style={{
                        left: props.quorum + "%",
                        opacity: props.disabled ? 0.3 : 1,
                    }}
                >
                    {"Quorum " + props.quorum + "%"}
                </div>
            </div>
        </div>
    );
}
